.products-layout {
    padding-top: 9rem;

}

/* .products-layout h6 {

    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    padding-top: 2rem;
    padding-left: 6.9rem;
} */

.discount-h6{

    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    text-align: center;
    padding-top: 2rem;
}

.discount-left{


    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    padding-top: 2rem;
    padding-left: 6.9rem;

}

.products-layout h5 {

    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    text-align: left;
    padding-left: 6.3rem;

}

.background-for-all {
    background-color: #EFF0EC;
}

.products-options {
    display: flex;
    column-gap: 12%;
    padding: 1.5rem;

}


.title_options {

    background-image: url('/webapps/tippytap/src/Assets/Frame 2459 (5).png');
    background-repeat: no-repeat;
    background-size: fill;
    height: 600px;  

}

.product-card-img{

    width: 100%;

}

@media (max-width: 480px) {
    /* .products-layout h6 {

        font-family: Poppins;
        font-size: 22px !important;
        font-weight: 600;
        line-height: 45px;
        padding-left: 0rem;
        text-align: center;
        clear: both;
        margin-left: auto;
        margin-right: auto;
    } */

    .products-options {

        display: flex;
        flex-direction: column;
        column-gap: 0%;

    } 

    .discount-left{


        font-family: Poppins;
        font-size: 30px;
        font-weight: 600;
        line-height: 45px;
        padding-top: 0rem;
        padding-left: 2rem;
    
    }


    .title_options {

        background-image: url('/webapps/tippytap/src/Assets/colorkit.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;  
        width: auto; /* Ensures full width of the viewport */
        border-radius: 90px;
    }

    
    .products-layout h5 {
    
        font-family: Poppins;
        font-size: 30px;
        font-weight: 600;
        line-height: 45px;
        text-align: center;
        padding-left: 0rem;
        padding-top: 2rem;
    }

    .discount-h6{

        font-family: Poppins;
        font-size: 22px;
        font-weight: 600;
        line-height: 45px;
        text-align: center;
        padding: 1rem;
        padding-top: 2rem;
    }


}

@media only screen and (min-width: 768px) and (max-width: 1366px) {

    .discount-left{

        font-family: Poppins;
        font-size: 20px !important;
        font-weight: 600;
        line-height: 45px;
        padding-left: 3.5rem;
        padding-bottom: 0rem;
        text-align: left;
    }

    .title_options {

        background-image: url('/webapps/tippytap/src/Assets/colorkit.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;  
        width: auto; /* Ensures full width of the viewport */
        border-radius: 90px;
        margin-bottom: 1rem !important;

    }


    .products-options {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 1rem; 
        background-repeat: no-repeat;
        background-size: fill !important; 
        background-position: center; 
        height: 100%;
        width: 100%;
    }
    

    .products-layout h5 {

        font-family: Poppins;
        font-size: 30px;
        font-weight: 600;
        line-height: 45px;
        text-align: center;
        padding-left: 0rem;
        padding-top: 1rem;
        padding-bottom: 0.5rem;
    
    }

    .product-card-img{

        width: 100%;
        height: 300px;
        
    }

    .width-for-img{

        width: 100%;
        height: 100%;
        
       

    }




}

