.Attachments-Section{

    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;

}

.Add-new-table-row {
    width: 130px;
    height: 41.89px;
    border-radius: 8.57px;
    box-shadow: 0px 0px 3.81px 0px #00000040;
    background: #ffffff;
    font-family: Poppins;
    font-size: 16.85px;
    font-weight: 500;
    line-height: 34.28px;
    color: #000000;
    border: 0px;
    margin-bottom: 1rem;
  }

  .table-striped td ,
  
  .table-striped th
  {

    text-align: center; /* Center text in table cells */

  }

  .bts-for-add-new{

    display: flex;
    justify-content: flex-end;

  }

  .table>:not(caption)>*>* {
    border-bottom-width: 0px !important;
}

.save-in-add-new{

    width: 120px;
    height: 46px;
    border-radius: 16px;
    border: 0px;
    background-color: #6CA468;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.1599999964237213px;
    text-align: center;
    color: white;

}

.cancel-in-add-new{
  width: 120px;
  height: 46px;
  border-radius: 16px;
  border: 0px;
  background-color: #DCDCDC;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1599999964237213px;
  text-align: center;
  color: white;
  border: 1px solid #DCDCDC;
}

.values-blues-remove a{

  text-decoration: none !important;
  color: #000000;

}

