.background-color-dummy {
    background: linear-gradient(to bottom, #BDAFFF, #E386FF);
    height: 100vh;
}

.logo-dummy {

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transform: translate(-50%, -50%);
    /* Adjust the position to center it */
    top: 45%;
    /* Push the element 50% down the screen */
    left: 50%;
    /* Push the element 50% right on the screen */
}

.logo-dummy img {

    width: 600px;

}

.leftball {

    position: absolute;
    top: 30%;
    animation: move-steps 3s linear forwards;
    /* Total duration includes both moves and the pause */

}

.lefting-star {

    position: absolute;
    top: 20%;
    right: 70%;
    animation: rotate-animation 2s linear infinite;

}

.top-white-ball {

    position: absolute;
    top: -10%;
    right: 40%;
    animation: move-white-ball 3s linear forwards;


}

.bottom-star-2 {


    position: absolute;
    top: 65%;
    right: 65%;
    animation: rotate-animation 1s linear infinite;

}

.bottom-ball {

    position: absolute;
    bottom: 1%;
    left: 69%;
    animation: purple-bottom-move  3s linear forwards;

}

.big-purple-star {

    position: absolute;
    top: 40%;
    left: 75%;
    animation: rotate-animation 1s linear infinite;


}

.right-ball {

    position: absolute;
    top: 40%;
    right: 0;
    animation: purple-ball-move-right  3s linear forwards;


}


@keyframes rotate-animation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes move-steps {
    0% {
        left: 0;
        /* Initial position */
    }

    20% {
        left: 15%;
        /* Move smoothly to 15% */
    }

    40% {
        left: 15%;
        /* Pause at 15% */
    }

    60% {
        left: 20%;
        /* Move smoothly to 20% */
    }

    80% {
        left: 20%;
        /* Pause at 20% */
    }

    100% {
        left: 30%;
        /* Pause at 20% */
    }
}



 @keyframes move-white-ball {
    0% {
        top: -10%;
    }

    20% {
        top: 10%;

    }

    40% {
        top: 10%;
    }

    60% {
        right: 40%;
        top: -10%;
    }

    80% {
        right: 40%;
        top: -10%;

    } 

    100% {
        top: 20%;
    } 

} 




@keyframes purple-ball-move-right {

    0% {
        right: 0;
        /* Initial position */
    }

    20% {
        right: 25%;
        /* Move smoothly to 15% */
    }

    40% {
        right: 25%;
        /* Pause at 15% */
    }
  

    60% {
        right:0%;

    }

    80% {
        right: 0%;
    } 

    100% {
        right: 35%;
    } 


}


@keyframes purple-bottom-move {

    0% {
        bottom: 0%;
    }
    20% {
        left: 50%;
        bottom: 30%;
    }

    40% {

        left: 50%;
        bottom: 30%;

    }
    60% {
        bottom: 0%;
        left: 50%;
    }

    80% {
        bottom: 0%;
        left: 50%;

    
    } 

    100% {
        left: 50%;
        bottom: 30%;
    } 


}


@media (max-width: 480px) {


.logo-dummy img {

    width: 300px;

}


.leftball {

    position: absolute;
    top: 30%;
    animation: move-steps 3s linear forwards;
    /* Total duration includes both moves and the pause */
    width: 200px;

}

.lefting-star {

    position: absolute;
    top: 20%;
    right: 70%;
    animation: rotate-animation 2s linear infinite;
    width: 100px;

}

.top-white-ball {

    position: absolute;
    top: -10%;
    right: 40%;
    animation: move-white-ball 3s linear forwards;
    width: 200px;


}

.bottom-star-2 {


    position: absolute;
    top: 65%;
    right: 65%;
    animation: rotate-animation 1s linear infinite;
    width: 100px;

}

.bottom-ball {

    position: absolute;
    bottom: 1%;
    left: 69%;
    animation: purple-bottom-move  3s linear forwards;
    width: 170px;

}

.big-purple-star {

    position: absolute;
    top: 40%;
    left: 72%;
    animation: rotate-animation 1s linear infinite;
    width: 100px;


}

.right-ball {

    position: absolute;
    top: 40%;
    right: 0;
    animation: purple-ball-move-right  3s linear forwards;

    width: 200px;
}


}

@media screen and (min-width: 768px) and (max-width: 1366px) 
{
    @keyframes purple-bottom-move {

        0% {
            bottom: 0%;
        }
        20% {
            left: 50%;
            bottom: 30%;
        }
    
        40% {
    
            left: 50%;
            bottom: 30%;
    
        }
        60% {
            bottom: 0%;
            left: 50%;
        }
    
        80% {
            bottom: 0%;
            left: 50%;
    
        
        } 
    
        100% {
            left: 50%;
            bottom: 40%;
        } 
    
    
    }
    
}




