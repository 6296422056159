.Verify-container {
  background-color: white;
  height: 100vh;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.Verify-header {
  background-image: url("/webapps/tippytap/src/Assets/image 114.png");
  background-size: contain;
  /* Ensures the image covers the entire container */
  background-repeat: no-repeat;

  /* Prevents the background from repeating */
  width: 100%;
  /* Full width of its container */
  height: 100%;
}

.Container-for-click {
  background-color: white;
  margin-top: 7rem;
  width: 700px;
  /* Full width of its container */
  height: 428px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 2px 4px 4px 2px #00000033;
  border-radius: 24px;
  
}

.img-white-container{
    display: flex;
    justify-content: center;

}

.verify-img-2 {
  width: 154.05px;
  height: 180px;
}

.flexing-container-ver {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .verify-data-holder {
  display: flex;
  justify-content: flex-start;
  border: 1px solid #705198;
  padding: 1rem;
  border-radius: 16px;
  padding-top: 3rem;
  padding-bottom: 3rem;
} */

.verify-data-holder h6 {
  font-family: Poppins;
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.verify-btn {
  width: 523px;
  height: 64px;
  border-radius: 50px;
  border: 0px;
  background-color: #705198;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  color: white;
}

.verify-img-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.verify-img-container .tippy-img {
  width: 200px;
  height: 66px;
}
