.Theme-component-style h6{

        font-size: 20px;
        padding-bottom: 1rem;


}

.img-selection{

    width: 100%;
    height: auto;
    border-radius: 25px;
    object-fit:fill;
}

.themes-alignments{

    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 3rem;

}

.form-check-them{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.form-check-input {
    display: none; /* Hides the radio button */
}

.theme-label {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.img-selection {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 8px;
}

.img-selection:hover {
    transform: scale(1.1);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.img-selection.selected {
    transform: scale(1);
    box-shadow: 0px 4px 15px rgba(0, 0, 255, 0.5); /* Blue glow when selected */
    border: 2px solid blue;
}

.theme-container {
    position: relative;
    display: inline-block;
}

.checkmark {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}



@media (max-width: 480px) {

    .themes-alignments{

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 2rem;
        column-gap: 3rem;
    
    }

}