.order-details-multi {

    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 24px 24px 24px 24px;
    padding-bottom: 0.2rem;
    margin-bottom: 2rem;


}

.order-details-multi .different-cards-content {

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;

}


.valid-icon-opacity{

    width: 120px;
    height: 37px;
}