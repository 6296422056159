.Set-data-container {
  width: 100%;
  height: auto  !important ;
  background: #FFFFFF;
  border-radius: 24px 24px 24px 24px;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  margin-top: 1rem !important;
}
.form-check-input:checked
{
  background-color: #705198 !important;
  border-color: #705198 !important;
}

.background-border-style {
  width: 100%;
  height: 100% !important;
  background: #FFFFFF;
  height: 240px;
  border: 1px solid #DCDCDC;
  padding: 2rem;
  padding-top: 1rem;
  border-radius: 24px 24px 24px 24px;

}

.flexing-personal-data-1 {


  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5%;
  row-gap: 0.5rem;
}

.personal-data-header-1 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #000000;
  padding-bottom: 0rem;

}

.background-border-style .form-control:disabled {


  background-color: #F6F6F6 !important;
}

.background-border-style .form-control {

  background-color: #E7E7E7 !important;

}

.h6-for-delete {

  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #000000;
  padding-bottom: 0.5rem;


}

.personal-data-header-2 {

  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #000000;

  padding-top: 2rem;
  padding-bottom: 1rem;

}



.personal-data-header-3 {

  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #000000;
  padding-top: 1rem;
  padding-bottom: 1rem;

}

.save-section-btn-style {}


.urls-input {}


.urls-input {
  padding: 20px;
  background-color: #D9D1E6;
  border-radius: 10px;

}

.flex-layout-input-purple-2 {

  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10%;
  justify-content: center;
  align-items: center;

}

.flex-layout-input-purple-2 .form-control {

  width: 100%;
}

.flexing-personal-data-1 .form-control {

  margin-top: 0.3rem;

}

.edit-btn-2 {
  width: 341px;
  height: 48px;
  border: 1px;
  border-radius: 25px;
  background: #705198;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;

  color: #FFFFFF;
}

.btns-view-save {

  margin-top: 1rem;
}


.save-btn-container-set-data {

  display: flex;
  float: right;

}

.Camera-img-container {
  position: relative;
  display: inline-block;
}

.camera-img {
  width: 185px !important;
  height: 160px !important;
  border-radius: 50% ;
  object-fit:cover;
}

.edit-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5em;
  /* Adjust the size as needed */
  color: #000;
  /* Choose your desired color */
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
  /* Optional: white background with slight transparency */
  border-radius: 50%;
  /* Optional: round the icon background */
  padding: 4px;
  /* Optional: add padding inside the circle */
}


.btn-view-style {
  margin-top: 3rem !important;
  width: 200px;
  height: 48px;
  border: 1px;
  border-radius: 25px;
  background: #705198;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  color: #FFFFFF;
}

.blocking-img-btn-view {

  display: flex;
  /* Centers items vertically */
  justify-content: space-between;
  /* Positions the button to the right */

}

.save-btn-container-set-data-2{

  display: flex;
  justify-content: flex-end;
  padding-top: 1.5rem;

}

@media (max-width: 480px) {

.camera-img {
  width: 142px !important;
  height: 142px !important;
  border-radius: 50%;
  object-fit: cover;
}
.btn-view-style {
  width: 150px;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 48px;
  border: 1px;
  border-radius: 25px;
  background: #705198;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  color: #FFFFFF;
  margin-top: 1rem;
}

.edit-btn-2 {
  width: 100px;
  height: 48px;
  border: 1px;
  border-radius: 25px;
  background: #705198;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;

  color: #FFFFFF;
}

.flexing-personal-data-1 {
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr 1fr;
  column-gap: 5%;
  row-gap: 0.5rem;
}

.personal-data-header-1 {
  padding-top: 2rem;
}

}