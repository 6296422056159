.PaginationPosition{


    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem !important;
    margin-top: -2.5rem;
}

.delete-icon-global{
    color: red;
    font-weight: 700;
    margin-right: 0.5rem;
    background-color: transparent;
    border: 0px;
    display: block;
    padding: 0.200rem;
    background-color: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
}

.first-delete-button{

    background-color: transparent;
    border: 0px;
    color: #D9D1E6;

}

.first-delete-button{

margin-top: 1.4rem;

}

.margin-the-delete-icon{

    position: relative;
}

.flexing-new-image-div{

    display: flex;
    flex-direction: column;

}

.images-areas{

    object-fit: fit;
    margin-top: 2rem;
    width: 100%;
}

.vertical-class{

    margin-top: 2rem;
    width: 300px !important;
    height: 400px ;

}

.horizontal-class{
    margin-top: 2rem;
    width: 347px ;
    height: 216px ;

}


.preview-defualt{


    width: 347px ;
    height: 216px;

}



.preview-default-vertical {
    margin-top: 2rem;
    width: 300px ;
    height: 400px ;

}
  
  .preview-default-horizontal {
    margin-top: 2rem;
    width: 347px ;
    height: 216px ;
}

 

    .delete-icon-mobile {
        display: none; /* Hide by default */
    }
    
    .delete-icon-desktop {
        display: inline; /* Show by default */
    }

    @media (max-width : 480px){

    .delete-icon-global{
        color: red;
        font-weight: 700;
        margin-right: 0.5rem;
        background-color: transparent;
        border: 0px;
        display: block;
        padding: 0.200rem;
        background-color: var(--bs-body-bg);
        border: var(--bs-border-width) solid var(--bs-border-color);
        border-radius: var(--bs-border-radius);
        width: 100% !important;
    }

    .first-delete-button{

        display: none;

    }





    @media only screen and (min-width: 600px) and (max-width: 1024px) {
        .delete-icon-mobile {
            display: inline; /* Show on mobile */
        }
        .delete-icon-desktop {
            display: none; /* Hide on mobile */
        }
    }

}


@media (max-width: 480px) {
    .delete-icon-mobile {
        display: inline; /* Show on mobile */
    }
    .delete-icon-desktop {
        display: none; /* Hide on mobile */
    }
    .preview-default-vertical {
        margin-top: 2rem;
        width: 100% ;
        height: auto ;
    
    }

    .preview-defualt{


        width: 100%;
        height: 189px;
    
    }
      
      .preview-default-horizontal {
        margin-top: 2rem;
        width: 100% ;
        height: auto ;  }

        .horizontal-class{
            margin-top: 2rem;
            width: 100% !important;
            height: auto ;
        
        }
}
    

/* Hide blue selection border */
.transparent-resizer .moveable-line {
    border-color: transparent !important;
}
.moveable-control-box {
    z-index: 10 !important; /* Lower than navbar */
}


/* Hide resize handles */
.transparent-resizer .moveable-control {
    opacity: 0 !important;  /* Fully transparent */
    border: none !important; /* No borders */
    background: none !important; /* No background */
    width: 0px !important;
    height: 0px !important;
    pointer-events: auto; /* Keep functionality */
}

