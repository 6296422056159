.pet-data-layout {

    display: grid;
    grid-template-rows: 1fr 1fr;
    flex-wrap: wrap;
    flex-grow: 3;
    column-gap: 1rem;
    padding-top: 3rem;
    padding-left: 2rem;
}

.personal-pet-information {

    display: flex;
    column-gap: 4rem;
    row-gap: 1rem;
}

.flexing-pets {

    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;
    place-items: center;
    padding-bottom: 1rem;
    padding-top: 2rem;
}

.pet-image {


    width: 238px;
    height: 206px;
}

.pet-img-container {


    border-right: 2px solid #C7C7C7;
}

.Nav-for-Profile-Scane-theme-3-pet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2rem;
    margin-bottom: 8rem;
}



.pet-img-container img {


    padding-right: 2rem !important;
}

.label-pet {


    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: #FA9AFF;

}


.label-pet span {
    font-size: 16px;
    color: #8086FF;
    font-weight: 500;

}

.personal-pet-container {

    width: 412px;
    height: 238px;
    border-radius: 24px;
    padding: 16px;
    background-color: #FAFAFA;


}

.personal-pet-container h3 {
    border-bottom: 1px solid #DCDCDC;
    line-height: 1.8;
    /* Adjust based on your preference */
    font-family: Poppins;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    padding-bottom: 10px;
}


.inputs-values-pets {


    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
}



@media (max-width: 480px) {

    .Nav-for-Profile-Scane-theme-3-pet{

        margin-bottom: 1rem;
    }

    .flexing-pets {

        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        place-items: center;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .personal-pet-container {

        width: 100%;
        height: auto;
        border-radius: 24px;
        padding: 16px;
        background-color: #FAFAFA;
    
    
    }
    .inputs-values-pets {


        display: flex;
        flex-direction: column;
        column-gap: 0.5rem;
    }
}

@media screen and (max-width: 1388px) {

    .flexing-pets {

        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        place-items: center;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .personal-pet-container {

        width: 100%;
        height: auto;
        border-radius: 24px;
        padding: 16px;
        background-color: #FAFAFA;
    
    
    }
    .inputs-values-pets {


        display: flex;
        flex-direction: column;
        column-gap: 0.5rem;
    }

}
  