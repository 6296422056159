.Business-sec {}

.flexing-my-Business-sec {

  padding-top: 1rem;
  display: flex;
}

.flexing-my-Business-sec-List {

  list-style-type: none;


}

.flexing-my-Business-sec-List li {
  margin-bottom: 1.5rem;
  cursor: pointer;
  color: #676B5F;
  display: flex;
  justify-content: center;
  width: 200px;
  padding: 0.5rem;
}


.no-cards-message {

  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  font-size: 20px;
  font-weight: 600;

}

.flexing-my-Business-sec-List li.active {
  background-color: white;
  color: black;
  /* Adjust text color if needed */
  font-weight: bold;
  border-radius: 10px 0px 0px 10px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #705198;
  box-shadow: -6px 0px 8px 0px #0000001A;
}

.Business-wrap-container {

  width: 100%;
  height: auto;
  background-color: white;
  padding: 40px 64px 40px 64px;
  border-radius: 24px 24px 24px 24px;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.Business-design-sec {}

.different-count-text {

  font-size: 20px;
  padding-left: 1rem;

}

.different-count-text-2 {

  font-size: 20px;
  padding-left: 1rem;
  margin-top: -0.35rem;
  font-weight: 600;

}

.insert-img-sec {

  width: 100%;
  height: auto;
  /* new design auto */
  padding: 16px;
  border-radius: 10px;
  border: 1px;
  border: 1px solid #DCDCDC;

}

.set-inputs-sec {
  width: 100%;
  /* height: 100%; //newdesign  */
  height: 100%;
  padding: 16px;
  border-radius: 10px;
  border: 1px;
  border: 1px solid #DCDCDC;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3%;
}

.custom-file-input-wrapper {
  position: relative;
  width: 100%;
}

.custom-file-input-wrapper input[type="file"] {
  position: relative;
  /* Keep it in normal flow */
  width: 100%;
  height: 100%;
  opacity: 0;
  /* Make the input invisible but clickable */
  cursor: pointer;
  /* Add pointer cursor to make it clear it's clickable */
  z-index: 2;
  /* Ensure the file input is above the placeholder */
}

.custom-file-input-wrapper .placeholder {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 10px;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 4px;
  color: #6c757d;
  box-sizing: border-box;
  position: absolute;
  /* Make placeholder absolute to overlay input */
  left: 0;
  top: 0;
  z-index: 1;
  /* Lower z-index than input so it's underneath */
  overflow: hidden;
  /* Prevent overflow */
  white-space: nowrap;
  /* Prevent text wrap */
  text-overflow: ellipsis;
  /* Add ellipsis if text is too long */
}

.custom-file-input-wrapper .camera-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
  pointer-events: none;
  /* Ensure the icon doesn’t block input clicks */
  z-index: 2;

}

.custom-file-input-wrapper input[type="file"]:hover+.placeholder {
  border-color: #80bdff;
}

.custom-file-input-wrapper input[type="file"]:focus+.placeholder {
  border-color: #007bff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.QR-selection-sec {

  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.QR-CONTAINER-WRAP p {

  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #000000;
  padding-top: 1rem;


}

.qr-icon-img {

  width: 100%;
  height: 100%;
  object-fit: contain;
  /* Maintain aspect ratio */

}

.save-screenshot-btn-qr-code {

  width: 347px !important;
  margin-top: 1rem;
  height: 48px;
  border-radius: 50px;
  border: 0px;
  background: #705198;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  color: white;

}

.image-container-1 {

  position: relative;
  width: 347px !important;
  height: 100% !important;


}

.verticalclass {
  
  width: 300px !important;
  height: 400px ;
}

.qr-code-overlay {

  position: absolute;
  cursor: move;


}

.area-to-drag {

  width: 100% !important;
  height: 216px;
}

.area-to-drag-2 {

  width: 347px ;
  height: 216px;
  object-fit: fill;
  display: block;
  
  
}

.react-draggable {

  width: auto;
  height: auto;


}

.Similar-different-data-sec {
  margin-top: 1rem;
  width: 100%;
  height: auto;
  border-radius: 16px;
  border: 1px 1px 1px 1px;
  border: 1px solid #DCDCDC;

}

.Selection-type-data-List {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  border-bottom: 1px solid #DCDCDC;
  padding-left: 0px;
}


.Selection-type-data-List li {
  text-align: center;
  width: 50%;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #676B5F;
  cursor: pointer;
  padding-top: 1rem;

}


.Selection-type-data-List li.active {

  background: #705198;
  color: white;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  padding: 1rem;
  border-radius: 16px 16px 0px 0px;
}


.similar-cards-content {

  padding: 1rem;

}

.different-cards-content {
  padding: 1rem;

}

.counter-container-cart-3 {
  padding-top: 0rem;
  padding-bottom: 1rem;
  margin-top: -0.2rem;
}


.counter-container-cart-4 {

  display: flex;
}

.displaying-card-quantity {

  display: flex;
  column-gap: 7%;

}


.special-quantity-div .accordion-button:focus,
.special-quantity-div.accordion-button.active {
  color: #000000 !important;

}

.similar-cards-content .accordion-button:focus,
.similar-cards-content.accordion-button.active {
  color: #000000 !important;

}

.different-cards-content .accordion-button:focus,
.different-cards-content .accordion-button.active {
  color: #000000 !important;

}


.similar-cards-content .accordion-header:focus,
.similar-cards-content .accordion-header.active {
  color: #000000 !important;
  border-bottom: 0px !important;

}

.different-cards-content .accordion-header:focus,
.different-cards-content .accordion-header.active {
  color: #000000 !important;
}

.special-quantity-div .accordion-header:focus,
.special-quantity-div .accordion-header.active {
  color: #000000 !important;
  border-bottom: 0px !important;

}

.special-quantity-div .accordion-button:focus,
.special-quantity-div .accordion-button:active {
  box-shadow: none !important;
  /* Removes the blue outline */
  outline: none !important;
  /* Removes any default outline */
  background-color: #EFEFEF !important;
  /* Optional: set background color */

}


.similar-cards-content .accordion-button:focus,
.similar-cards-content .accordion-button:active {
  box-shadow: none !important;
  /* Removes the blue outline */
  outline: none !important;
  /* Removes any default outline */
  background-color: #EFEFEF !important;
  /* Optional: set background color */

}

.different-cards-content .accordion-button:focus,
.different-cards-content .accordion-button:active {
  box-shadow: none !important;
  /* Removes the blue outline */
  outline: none !important;
  /* Removes any default outline */
  background-color: #EFEFEF !important;
  /* Optional: set background color */

}

.special-quantity-div .accordion-button:not(.collapsed) {
  background-color: #EFEFEF;
  border-radius: 16px !important;
}

.similar-cards-content .accordion-button:not(.collapsed) {
  background-color: #EFEFEF;
  border-radius: 16px !important;
}

.different-cards-content .accordion-button:not(.collapsed) {
  background-color: #EFEFEF;
  border-radius: 16px !important;


}



.special-quantity-div .accordion-item {
  border-radius: 16px !important;
  margin-bottom: 1rem;
  /* background-color: #b984c3; */


}


.similar-cards-content .accordion-item {
  border-radius: 16px !important;
  margin-bottom: 1rem;
  /* background-color: #b984c3; */


}

.different-cards-content .accordion-item {
  border-radius: 16px !important;
  margin-bottom: 1rem;
  /* background-color: #b984c3; */

}




.special-quantity-div .accordion-button {

  background-color: #EFEFEF;
  border-radius: 16px 16px !important;
  color: #000000;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  text-align: left;


}

.similar-cards-content .accordion-button {

  background-color: #EFEFEF;
  border-radius: 16px 16px !important;
  color: #000000;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  text-align: left;


}

.different-cards-content .accordion-button {

  background-color: #EFEFEF;
  border-radius: 16px 16px !important;
  color: #000000;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  text-align: left;


}

.inset-into-inputdata {
  padding: 20px;
  background-color: #D9D1E6;
  border-radius: 10px;

}

.flex-layout-input-purple {

  display: flex !important;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  margin-bottom: 1rem;
}


.flex-layout-input-purple-7{

  display: flex ;
  column-gap: 1rem;
  place-items: center;
  margin-bottom: 1rem;

}

.flex-layout-input-purple-5{

  display: flex;
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  column-gap: 1rem;
  margin-bottom: 1rem;
  place-items: center;

}



.flex-layout-input-purple-3 {

  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  align-items: center;
  place-items: center;
  margin-bottom: 0px;
  margin-bottom: 1rem;
}


.flex-layout-input-purple-6{
  display: flex;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
  margin-bottom: 1rem;
  place-items: center;

}

.grid-labels {

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  padding-bottom: 0.5rem;

}

.grid-labels-2 {

  display: flex;
  column-gap: 3rem;
  margin-bottom: 0px;
  padding-bottom: 0.5rem;
  justify-content: center;
}

.grid-labels-2 label {

  width: 100px;

}

.grid-labels label {

  width: 230px;

}


.flex-layout-input-purple .form-control {

  width: 230px !important;

}

@media (min-width: 1399px) {

  .flex-layout-input-purple .form-control {

    width: 205px;

  }

}



/* .inset-into-inputdata .form-control {

  width: 200px;

} */

.add-icon-btn-container {

  display: flex;
  justify-content: flex-end;
  /* This will push the button to the right */
  padding-top: 0.7rem;

}

.add-icon-btn-container-2 {

  display: flex;
  /* justify-content: flex-end; */
  /* This will push the button to the right */
  padding-top: 1rem;
  justify-content: space-between;

}

.add-icon-btn-container-3 {

  display: flex;
  /* justify-content: flex-end; */
  /* This will push the button to the right */
  padding-top: 1rem;
  justify-content: center;

}

.add-icon-btn-1 {
  background-color: #6C4CA3;
  border: none;
  color: white;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
  font-size: 24px;
  cursor: pointer;
}

.Modal {
  background: white;
  padding: 20px;
  border: 2px solid #EFF0EC !important;
  border-radius: 4px;
  width: 832px;
  height: auto;
  top: 636px;
  max-width: 500px;
  margin: auto;
  /* Center horizontally */
  position: relative;
  z-index: 1000;
  top: 50%;
  left: 14%;
  transform: translate(-50%, -50%);
  /* Center the modal */
  border-radius: 10px;
  padding: 2rem;
}

.Overlay {
  /* background: rgba(0, 0, 0, 0.5); */
  position: fixed;
  /* Ensure overlay covers the entire screen */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  /* Must be less than the modal's z-index */
}


.Modal h2 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 35px;
  margin-bottom: 1rem;

}

.Modal p {

  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;

}

.model-btns-sec {

  display: flex;
  column-gap: 5%;


}

.cancel-modal-btn {

  width: 100%;
  height: 50px;
  border-radius: 50px;
  border: 1px;
  border: 1px solid #705198;
  background-color: transparent;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;


}

.confirmm-modal-btn {

  width: 100%;
  height: 50px;
  border-radius: 50px;
  border: 0px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  background-color: #705198;
  color: white;

}



.save-btn-sec-set-all-data-container {

  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.save-btn-sec-set-all-data {

  width: 268px;
  height: 48px;
  border-radius: 50px;
  background: #705198;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  color: white;
  border: 0px;


}


.back-section img {

  cursor: pointer;
  width: 36px;

}

.qr-code-overlay {
  position: absolute;
  z-index: 10;
  /* Keep above the image */
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  /* Optional for visibility */
  border: 1px dashed #000;
  /* Optional to highlight draggable area */
}

.small-text-tag{

  font-size: 13px;
  
}


@media (max-width: 480px) {

  .flex-layout-input-purple-3 {

    box-shadow: 0px 0px 4px 0px #00000040;
    border: 1px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;  
    border-radius: 20px;
    padding: 1rem;
    justify-content: center;

  }

  .flex-layout-input-purple-5{
    box-shadow: 0px 0px 4px 0px #00000040;
    border: 1px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;  
    border-radius: 20px;
    padding: 1rem;
    justify-content: center;

  }

  

  .flex-layout-input-purple-6{
    box-shadow: 0px 0px 4px 0px #00000040;
    border: 1px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;  
    border-radius: 20px;
    padding: 1rem;
    justify-content: center;
    
  }


  .flex-layout-input-purple-7{

    box-shadow: 0px 0px 4px 0px #00000040;
    border: 1px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;  
    border-radius: 20px;
    padding: 1rem;
    justify-content: center;
  }

  
.Modal {
  background: white;
  padding: 20px;
  border: 3px solid #EFF0EC !important;
  border-radius: 4px;
  width: 90%;
  height: auto;
  top: 636px;
  max-width: 500px;
  margin: auto;
  /* Center horizontally */
  position: relative;
  z-index: 1000;
  top: 50%;
  left:45%;
  transform: translate(-50%, -50%);
  /* Center the modal */
  border-radius: 10px;
  padding: 2rem;

}

  .flexing-my-Business-sec {

    padding-top: 4rem;
    display: flex;
    flex-direction: column;
  }

  .flex-layout-input-purple{
    box-shadow: 0px 0px 4px 0px #00000040;
    border: 1px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;  
    border-radius: 20px;
    padding: 1rem;
  }

  .flexing-my-Business-sec-List {

    list-style-type: none;
    display: flex;
    padding-left: 0rem;
    margin-bottom: 0px;
  }


  .flexing-my-Business-sec-List li {
    margin-bottom: 0rem;
    cursor: pointer;
    color: #676B5F;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;


  }


  .flexing-my-Business-sec-List li.active {
    background-color: white;
    color: black;
    /* Adjust text color if needed */
    font-weight: bold;
    border-radius: 10px 10px 0px 0px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: #705198;
    box-shadow: -6px 0px 8px 0px #0000001A;
  }

  .insert-img-sec {

    width: 100% !important;
    height: auto;
    padding: 16px;
    border-radius: 10px;
    border: 1px;
    border: 1px solid #DCDCDC;

  }

  .set-inputs-sec {
    width: 100% !important;
    height: auto;
    padding: 16px;
    border-radius: 10px;
    border: 1px;
    border: 1px solid #DCDCDC;
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3%;
  }

  .Business-wrap-container {
    margin-top: -0.2rem;
    width: 100%;
    height: auto;
    background-color: white;
    padding: 0px !important;
    border-radius: 0px 0px 24px 24px;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: block;
    justify-content: center;
    align-items: center;
  }

  .QR-selection-sec {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;
}

  .QR-CONTAINER-WRAP p {

    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #000000;
    padding-top: 1rem;


  }

  .save-screenshot-btn-qr-code {

    width: 100% !important;
    height: 48px;
    border-radius: 50px;
    border: 0px;
    background: #705198;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    color: white;

  }

  .area-to-drag {

    width: 100% !important;
    height: 189px !important;

  }

  .Similar-different-data-sec {
    margin-top: 1rem;
    width: 100%;
    height: auto;
    border-radius: 16px;
    border: 1px 1px 1px 1px;
    border: 1px solid #DCDCDC;

  }

  .inset-into-inputdata {
    padding: 10px;
    background-color: #D9D1E6;
    border-radius: 10px;


  }

  .flex-layout-input-purple {

    flex-direction: column;

  }



  .save-btn-sec-set-all-data {

    width: 100%;

  }

  .Business-wrap-container .accordion-body {

    padding: 10px;

  }

  .flex-layout-input-purple .form-control {

    width: 100% !important;
  
  }

}


.display-none-card-data {

  display: none !important;
}


.download-btn-blue{

  padding: 8px 16px 8px 16px;
  border-radius: 50px;
  background-color: transparent;
  border: 0px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #0000EE;
  text-decoration: underline;

}



.Upload-btn-blue{

  width: 432px;
  height: 56px;
  padding: 8px 16px 8px 16px;
  border-radius: 50px;
  background: #D9D0FF;
  border: 0px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: #000000;

}

.Flexing-excel-btns{

  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 3rem;
}

.header-for-upload{

  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  margin-bottom: 1rem;


}

.download-icon-size{

  width: 18px;
  height: 18px;
  margin-top: -0.3rem;
}

.next-btn-container{

  display: flex;
  justify-content: flex-end;


}

.next-btn-container button{

  width: 100px;
  height: 56px;
  padding: 8px 16px 8px 16px;
  border-radius: 50px;
  background: #D9D1E6;
  border: 0px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: #000000;
  margin-right: 1rem;

}

/* Tablet-specific styles */
@media screen and (min-width: 768px) and (max-width: 1024px) {

  .set-inputs-sec {
    width: 100%;
    height: 100%;
    padding: 16px;
    border-radius: 10px;
    border: 1px;
    border: 1px solid #DCDCDC;
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3%;
}
.QR-selection-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
}

.flex-layout-input-purple {
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  margin-bottom: 1rem;
  width: 100%;
}

.Modal {
  background: white;
  padding: 20px;
  border: 0px !important;
  border-radius: 4px;
  width: 100%;
  height: auto;
  top: 636px;
  max-width: 500px;
  margin: auto;
  /* Center horizontally */
  position: relative;
  z-index: 1000;
  top: 40%;
  left:28%;
  transform: translate(-50%, -50%);
  /* Center the modal */
  border-radius: 10px;
  padding: 2rem;

}
}


@media only screen and (min-width: 600px) and (max-width: 1024px) {



  .flex-layout-input-purple-3 {

    box-shadow: 0px 0px 4px 0px #00000040;
    border: 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 1rem;  
    border-radius: 20px;
    padding: 1rem;
    justify-content: center;

  }

  .flex-layout-input-purple-5{
    box-shadow: 0px 0px 4px 0px #00000040;
    border: 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 1rem;  
    border-radius: 20px;
    padding: 1rem;
    justify-content: center;
  }


  .flex-layout-input-purple{

    box-shadow: 0px 0px 4px 0px #00000040;
    border: 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 1rem;  
    border-radius: 20px;
    padding: 1rem;
    justify-content: center;

  }

}

select.form-control {
  position: relative;
  z-index: 1000; /* Ensures dropdown appears on top */
}

select.form-control:focus {
  overflow: visible !important;
}


input::-webkit-calendar-picker-indicator {
  display: none !important;
}

/* This won't work in all browsers */
datalist {
  max-width: 150px !important; /* Set the max height */
  overflow-y: auto; /* Enable scrolling */
}