.get-your-card-btn-four {
    width: 177px;
    height: 58px;
    border-radius: 16px;
    background: linear-gradient(90deg, #E3B7F3 18.5%, #B6ACE9 63%, #9AA7EA 100%);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #FAFAFA;
    border: 0px;
}

.profile-number-four {

    width: 494px;
    height: 100%;
    background: linear-gradient(90deg, #F5DDFF 0%, #D6DDFF 49.5%, #F8E9FF 100%);
    box-shadow: 0px 4px 4px 0px #00000029;
    border-radius: 16px;
    padding-bottom: 2rem;

}

.flexing-three-icons-qr-save{

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding-top: 1rem;
    width: 100%;
}

.flexing-light-purple{
    display: flex;
    justify-content: center;
    align-items: center;
}

.light-purple-container{

    margin-top: 2rem;
    width: 446px;
height: 100%;
border-radius: 16px;
background: linear-gradient(180deg, #E3B7F3 18.5%, #B6ACE9 63%, #9AA7EA 100%);

}

.flexing-small-logo{

    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.5rem;

}

.sec-layout-images{

    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.5rem;
    gap: 30px;

}

.flexing-names{
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 0.5rem;
}

.flexing-names h5 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 24px;
    line-height: 28.13px;
    letter-spacing: 2%;
    text-align: center;
    color: white;
    

}

.flexing-names p {

    font-family: Roboto;
    font-weight: 500;
    font-size: 20px;
    line-height: 23.44px;
    letter-spacing: 2%;
    text-align: center;
    color: white;

}

.data-container-new-four{

    width: 447px;
    height: 58px;
    border-bottom-width: 3px;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-left: 16px;
}

.data-container-new-four p{
color:#FBFBFB ;
font-family: Inter;
font-weight: 500;
font-size: 18px;
line-height: 21.78px;
letter-spacing: 0%;

}

.ul-for-attachment-new-theme-four{
    list-style-type: none;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 2rem;
    padding-top: 1.2rem;

}


.attachment-container-element-new-theme-four {

    width: 447px;
    height: 58px;
    border-radius: 16px;
    border-bottom-width: 3px;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-left: 16px;
    display: flex;
    justify-content: space-between;

}

.ul-for-attachment-new-theme-four .paragraph-for-attachment-new-theme-four {

    width: 80px;
    text-align: center;
    border: 0px;
    height: 38px;
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 55.1px;
    font-weight: 700;
    font-size: 16px;
    color: white;
    background: linear-gradient(180deg, #E3B7F3 18.5%, #B6ACE9 63%, #9AA7EA 100%);
    padding-top: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 80px;
    max-width: fit-content;
    /* Adjust to fit 3 characters */

}

.img-four-theme{

    width: 120px;
    height: 120px;
    border-radius: 50%;

}

.ul-for-attachment-new-theme-four .btn-theme-one-purble-theme-four {
    width: 142px;
    height: 32px;
    border-radius: 16px;
    padding-top: 4px;
    padding-right: 16px;
    padding-bottom: 4px;
    padding-left: 16px;
    background: linear-gradient(180deg, #E3B7F3 18.5%, #B6ACE9 63%, #9AA7EA 100%);
    border: 0px;
    color: white;
    font-family: Inter;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.36px;
    letter-spacing: 0%;
}

.qr-code-pop-up-img{


    width: 300px;
    height: 100%;
}

@media (max-width: 480px) {

    .personaldata-section-theme-2 {
        margin-top: 12rem;
        background: #FBFBFB;
        width: 100%;
        height: 100%;
        border-top-left-radius: 64px;
        border-top-right-radius: 64px;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        position: relative;
        padding-bottom: 1rem;
    }


    .New-Profile-2-contanier {
        width: 100%;

    }

    .data-container-new-four {
        width: 95%;
     
    
    
    }



    .attachment-container-element-new-theme-four {

        width: 95%;

    }




    .get-your-card-btn-four{

        
        width: 100%;
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
    }


    .profile-number-four {

        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #F5DDFF 0%, #D6DDFF 49.5%, #F8E9FF 100%);
        box-shadow: 0px 4px 4px 0px #00000029;
        border-radius: 16px;
        padding-bottom: 2rem;
    
    }
    

    
    .light-purple-container{
    
        margin-top: 2rem;
        width: 95%;
    height: 100%;
    border-radius: 16px;
    background: linear-gradient(180deg, #E3B7F3 18.5%, #B6ACE9 63%, #9AA7EA 100%);
    
    }

    .flexing-three-icons-qr-save img{

        width: 90px;
    }

    

}