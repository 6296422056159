.Whole-Multi-logic {
  border: 1px solid #DCDCDC;
  border-radius: 24px 24px 24px 24px;
  margin-bottom: 1rem;
  background-color: white;
  padding: 1rem;
}

.Selection-type-data-multi {}

.insert-img-sec-2 {

  width: 100%;
  height: 100%;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #DCDCDC;

}

.name-of-card{

  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #000000;
  padding-bottom: 0.5rem;

}


.inset-into-inputdata-multi {
  padding: 1rem;
  background-color: #D9D1E6;
  border-radius: 10px;

}

.cards-container-flex-2 {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr ;
  column-gap:1rem;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  place-items: center;
}

/* .verticalclass {

  width: 250px ;
  height: 400px !important;

} */

.flex-layout-input-purple-multi {

  display: flex;
  margin-bottom: 0px;
  width: 100%;
  column-gap: 1%;
  
}

.inset-into-inputdata-multi .form-control {

  width:  297px;

}

.save-btn-sec-set-all-data-container-multi{
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  padding-right: 0px;

}

@media (max-width: 480px) {
  .cards-container-flex-2 {
    padding: 1rem;
    display: flex !important;
    flex-direction: column;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
    place-items: center;
}
}



@media (max-width: 1399px) {
  .inset-into-inputdata-multi .form-control {
    width: 250px;
  }
}

@media (max-width: 1399px) {
  .cards-container-flex-2 {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    column-gap:1rem;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
    place-items: center;
  }
}





