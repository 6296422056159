.Nav-for-Profile-Scane-theme-3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2rem;
}



.background-color-profile-scanning-3 {

    background-color: #F1F1F1;
    padding-bottom: 1rem;
    height: 100%;


}

.background-color-profile-scanning-3-pets {

    background-color: #F1F1F1;
    height: 100vh;
    overflow-y: auto;

}

.image-cover {
    background-image: url('/webapps/tippytap/src/Assets/image 1 (2) (1) (1).png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}


.ul-for-social-media-theme-3 {
    list-style-type: none;
    display: flex;
    justify-content: center;
    column-gap: 0.5rem;
    padding: 0px;
}

.personal-information-theme3 {

    width: 500px;
    height: auto;
    padding: 16px;
    border-radius: 24px;
    padding: 32px;
    border: 1px solid #C7C7C7;
}

.personal-information-theme3-pets {
    width: 100%;
    height: auto;
    padding: 16px;
    border-radius: 24px;
    padding: 32px;
    border: 1px solid #C7C7C7;
}



.personal-information-theme3 h6 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: #000000;
}


.data-layout-theme-3 {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    column-gap: 1.2rem;
    padding-top: 1rem;
}

.card-layout-profile-theme-data-element-theme-3 {
    border: 1px solid #C7C7C7;
    width: 100%;
    height: 88px;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    justify-content: flex-start;
    column-gap: 1rem;
}


.attachment-theme-3 {
    margin-top: 3rem;
    width: 500px;
    height: auto;
    padding: 16px;
    border-radius: 24px;
    padding: 32px;
    border: 1px solid #C7C7C7;


}

.attachment-ul-theme-3 {
    list-style-type: none;
    padding: 0px;
    display: flex;
    column-gap: 1.2rem;
    padding-top: 1rem;
    margin-bottom: 0px;
}


.attachment-container-element-theme-3 {
    width: 100%;
    height: 72px;
    border-radius: 16px;
    padding: 8px 16px 8px 16px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #C7C7C7;
}

/* .attachment-container-element-theme-3 img {

    color: black !important;

} */

.attachment-container-element-theme-3 p{

    margin-top: 0.7rem;


}

.Attachment-icon-theme3 {}


.data-for-attachment-container-theme3 {
    display: flex;

}


.downloadpurpuleicon{

    width: 32px;
    height: 32px;
    margin-top: 0.5rem;
}

.paragraph-for-attachment-theme-3{

    padding-top: 0.9rem;


}

.extra-ul-theme-3{
    list-style-type: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
    column-gap: 1.2rem;
    padding-top: 1rem;
    margin-bottom: 0px;
}


.extra-data-element-theme-3 {
    width: 100%;
    height: auto;
    padding: 8px 16px 8px 16px;
    border-radius: 100px;
    display: flex;
    justify-content: flex-start;
}

.extra-data-element-theme-3 h6 {
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #000000;
    padding-top: 0.7rem;
    margin-left: 0.5rem;
}

.extra-data-element-theme-3 p {
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #000000;
    padding-top: 0.7rem;
    margin-left: 0.5rem;
}

@media (max-width: 480px) {


    .data-layout-theme-3 {

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 1.2rem;
        padding-top: 1rem;
        row-gap: 1rem;
    
    }


    .card-layout-profile-theme-data-element-theme-3 {

        border: 1px solid #C7C7C7;
        width: auto;
        height: 88px;
        padding: 8px;
        border-radius: 16px;
        display: flex;
        justify-content: flex-start;
        column-gap: 1rem;
    }

    .card-layout-profile-theme-data-element-theme-3 .data-beside-vector h6 {

        font-size: 14px;

    }

    .card-layout-profile-theme-data-element-theme-3 .data-beside-vector p {

        font-size: 12px;

    }

    .personal-information-theme3 {
        width: 100%;
        height: auto;
        border-radius: 24px;
        padding: 16px;
        border: 1px solid #C7C7C7;
    
    }



.attachment-theme-3 {
    margin-top: 3rem;
    width: 100%;
    height: auto;
    padding: 16px;
    border-radius: 24px;
    border: 1px solid #C7C7C7;


}

.attachment-ul-theme-3 {
    list-style-type: none;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1.2rem;
    padding-top: 1rem;
    margin-bottom: 0px;
}


.attachment-container-element-theme-3 {

    width: 100%;
    height: 72px;
    border-radius: 16px;
    padding: 8px 16px 8px 16px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #C7C7C7;

}



.image-cover {

    background-image: url('/webapps/tippytap/src/Assets/image 1 (2).png');
    background-size:contain;
    /* Ensures the image covers the entire container */
    background-repeat: no-repeat;
    /* Prevents the background from repeating */
    width: 100%;
    /* Full width of its container */
    height: 100%;
}


}

