.business-status-container {
  width: 100%;
  height: 100% !important;
  background: #ffffff;
  height: 240px;
  min-height: 238px;
  border-radius: 24px 24px 24px 24px;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  padding-top: 1rem;
}

.card-details-titles {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #000000;
  padding-bottom: 1rem;
}

.business-status-list {
  list-style-type: none;
  display: flex;
  padding: 2rem;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-bottom: 0rem;
  border-bottom: 1px solid #dcdcdc;
}

.business-status-list li {
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.business-status-list li.active {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  color: #705198;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #705198;
  width: 100%;
}

.status-New-btn {
  width: 133px;
  height: 48px;
  border-radius: 50px;
  background: #5d2b6c;
  color: white;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  border: 0px;
  margin-bottom: 1rem;
}

.blue-container {
  background: #efefef;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  place-items: center;
  /* Centers both horizontally and vertically */
  align-items: center;
  padding-top: 1rem;
  border-radius: 10px;
  margin-top: 1rem;
  cursor: pointer;
}

.blue-container-data-flexing span {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: #676867;
}

.blue-container-data-flexing p {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #000000;
}

.Available-collapse-header {
  background: transparent;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  place-items: center;
  align-items: center;
  border-radius: 10px;
  column-gap: 4rem;
}

.status-content .accordion-button::after {
  display: none !important;
}

.status-content .accordion-button:focus,
.status-content.accordion-button.active {
  color: #543769 !important;
}

.status-content .accordion-header:focus,
.status-content .accordion-header.active {
  color: #543769 !important;
}

.status-content .accordion-button:focus,
.status-content .accordion-button:active {
  box-shadow: none !important;
  /* Removes the blue outline */
  outline: none !important;
  /* Removes any default outline */
  background-color: #efefef;
  /* Optional: set background color */
}

.status-content .accordion-button:not(.collapsed) {
  background-color: #efefef;
}

.status-content .accordion-button {
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 600;
  color: #543769 !important;
  display: flex;
  padding: 0px;
  padding-top: 1rem;
  background-color: #efefef;
  height: 80px;
  place-items: center;
  place-content: center;
}

.status-content .accordion-button {
  margin: 0px !important;
}

.blue-container-data-flexing {
  display: flex;
  cursor: pointer;
}

.available-card-temp-img {
  position: relative; /* Keep the second image in normal flow */
  z-index: 1; /* Ensure it's behind the overlay */
  width: 100%; /* Adjust size as needed */
  height: 100%; /* Adjust size as needed */
  object-fit: contain;
}

.available-card-temp-img-container {
  position: relative; /* Make this container relative so child elements can be positioned absolutely */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.valid-icon-opacity {
  position: absolute; /* Overlay the Available image on top of the second image */
  top: -7%; /* Adjust positioning as needed */
  left: 55%; /* Adjust positioning as needed */
  z-index: 2; /* Ensure it appears above the second image */
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
}

.position-relative-img button {
  position: absolute; /* Make the first image absolute */
  top: 0; /* Adjust the top positioning as needed */
  left: 55%; /* Adjust the left positioning as needed */
  margin-top: -1rem; /* Ensure it's layered above the second image */
  width: 120px;
  height: 37px;
  border-radius: 50px ;
  background: #7c7c7c;
  color: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  border: 0px;
  cursor: none;
}

.available-card-temp-img {
  position: relative;
  z-index: 1; /* Second image will be behind the first image */
  width: 200px;
  height: 150px;
  object-fit: contain;
  margin-bottom: 1rem;
}

.available-card-temp {
  width: 281px;
  height: 331px;
  padding: 24px;
  border-radius: 20px;
  border: 1px solid #dcdcdc;
  cursor: pointer;
}

.available-card-temp-body {
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
}

.available-card-temp-element {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.flexing-quantity-container {
  display: flex;
  column-gap: 1rem;
}

.flexing-quantity-element {
  display: flex;
  flex-direction: column;
}

.flexing-quantity-element span {
  font-size: 11px;
}

.flexing-quantity-element p {
  width: 46px;
  height: 29px;
  padding: 4px;
  border-radius: 6px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: #000000;
  background: #efefef;
}

.available-card-temp-element-header {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #676867;
}

.available-card-temp-element-header-2 {
  background: #efefef;
  width: 100px;
  text-align: center;
  gap: 8px;
  border-radius: 6px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: #000000;
  margin-bottom: 5px;
}

.available-card-temp-element-header-2-categoty {
  background: #efefef;
  width: 100px;
  text-align: center;
  gap: 8px;
  border-radius: 6px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: #705198;
  margin-bottom: 5px;
}

.cards-container-flex {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  column-gap: 3%;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
  .blue-container {
    background: #efefef;
    display: flex;
    flex-direction: column;
    place-items: center;
    /* Centers both horizontally and vertically */
    align-items: center;
    padding-top: 1rem;
    border-radius: 10px;
    margin-top: 1rem;
    cursor: pointer;
  }

  .business-status-list li {
    cursor: pointer;
    width: 100%;
    text-align: center;
    font-size: 13px;
  }

  .business-status-list li.active {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    color: #705198;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #705198;
    width: 100%;
    font-size: 13px;
  }
  


}



/* Tablet-specific styles */
@media screen and (min-width: 768px) and (max-width: 1024px) {

  .blue-container-data-flexing {
    display: flex;
    flex-direction: column;
  }

  .available-card-temp-img {
    position: relative;
    z-index: 1; /* Second image will be behind the first image */
    width: 100%;
    height: 150px;
    object-fit: contain;
    margin-bottom: 1rem;
  }
  
  .available-card-temp {
    width: 100%;
    height: 331px;
    padding: 24px;
    border-radius: 20px;
    border: 1px solid #dcdcdc;
    cursor: pointer;
  }
  .valid-icon-opacity {
    width: 100px !important;
    height: 37px;
  }
  .valid-icon-opacity {
    position: absolute;
    top: -7%;
    left: 47%;
    z-index: 2;
    width: 50px;
    height: 50px;
}


}

