.footer {

    width: 89.5% !important;
    border-radius: 26px;
    background-color: white;
    padding-left: 2rem;
    padding-right: 2rem;

}

.Footer-background {

    background-color: #4E38AA !important;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-image: url('/webapps/tippytap/src/Assets/Vector (2).png');
    background-repeat: no-repeat;
    background-size: auto;
}

.Wrap-footer {
    padding-top: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 8%;
    padding-left: 2rem;
    padding-right: 2rem;
}

.sec-footer-header {

    font-family: Poppins;
    font-size: 23px;
    font-weight: 700;
    line-height: 31.2px;
    letter-spacing: -0.23999999463558197px;
    text-align: left;
    color: #1E2330;
    padding-bottom: 1.5rem;

}

.sec-one-footer ul {

    text-decoration: none;
    list-style: none;
    margin: 0px;
    padding: 0px;

}

.sec-one-footer ul li {

    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.5px;
    letter-spacing: 0.12999999523162842px;
    text-align: left;
    color: #676B5F;
    padding-bottom: 1rem !important;



}

.btns-and-rest {

    padding-bottom: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 1.5rem;
    column-gap: -5%;
}

.footer-btns-only {

    display: flex;

}

@media (max-width: 480px) {


    .Wrap-footer {
        padding-top: 3rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 8%;
    }

    .sec-one-footer ul li {

        font-size: 12px;

    }


    .sec-footer-header {

        font-size: 20px;
        padding-bottom: 1.5rem;

    }

    .btns-and-rest {

        padding-bottom: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        padding-top: 1.5rem;
        column-gap: -5%;
    }

    .rest-icons {

        display: none;
    }


}

@media only screen and (min-width: 768px) and (max-width: 1024px) {


    .rest-icons {

        display: none;
    }

}