.cart-wrapper {
    padding-left: 6.3rem;
    width: 90%;
    padding-bottom: 3rem;
}

.flexing-cart {

    display: flex;
    column-gap: 12%;
}


.cart-product-container {

    width: 855px;
    height: 182px;
    box-shadow: 2px 2px 14.6px 1px #0000000D;
    border-radius: 24px;
    background-color: white;
    padding: 1.5rem;
}

.cart-product {
    height: 150px;
    width: 158px;
}

.cart-product-layout {

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 5%;
    align-items: center;
    row-gap: 1rem;
    transition: transform 0.2s ease;
    /* Smooth transition for the zoom effect */

}

.cart-items {
    display: flex;
    row-gap: 1rem;
    flex-direction: column;
    padding-bottom: 3rem;
}

.zabala {

    width: 25px;
    height: 25px;
    margin-top: -1.1rem;
    margin-left: 2rem;
}

.cart-product {

    width: auto;
}


.counter-container-cart-2 {
    padding-top: 0rem;
    padding-bottom: 1rem;
}


.counter-value-2 {
    width: 50px;
    height: 30px;
    border-radius: 50px;
    border: 0px solid #4A27DF;
    background-color: transparent;
    font-family: Poppins;
    font-size: 20px;
    padding-top: 0rem;
    font-weight: 500;
    text-align: center;
    box-sizing: border-box;
    display: inline-block;
}


.aed-price {
    margin-top: -0.5rem;
    font-family: Poppins;
    font-size: 21px;
    font-weight: 700;
    line-height: 33px;
    text-align: left;
    color: #000000;



}

.clear-cart-container {

    margin-left: auto;

}

.clear-cart-btn {

    color: #FAFAFA;
    background-color: #6423DB;
    border: 0px;
    text-decoration: none;
    width: 120px;
    height: 36px;
    border-radius: 25px;
    font-weight: 700;


}

.empty-cart-message {

    display: flex;
    justify-content: center;
    align-items: center;

}

.empty-cart-message h5 {
    padding-top: 6.5rem;
    font-size: 30px;
}

.checkout-container-summary {
    position: sticky;
    top: 22% !important;
    /* Adjust this value as needed */
    background: #4C4B95;
    padding: 1.5rem;
    width: 384px;
    height: 100%;
    border-radius: 24px;
    margin-bottom: 2.6rem;
}

.checkout-container-summary h6 {

    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    color: #FFFFFF;
    padding-bottom: 1rem;
    border-bottom: 1px solid #DCDCDC;

}

.whole-titles-cart {
    border-bottom: 1px solid #DCDCDC;
    padding-top: 0.1rem;
}

.FLex-total {

    display: flex;
    justify-content: space-between;
    margin: 10px 0;

}

.FLex-total p {

    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #FFFFFF;

}

.FLex-total span {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #FFFFFF;
    text-align: right;
    margin-left: auto;
}


.FLex-total-2 {

    display: flex;
    justify-content: space-between;
    margin: 10px 0;

}

.FLex-total-2 p {

    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;

    color: #FFFFFF;

}

.FLex-total-2 span {
    font-family: Poppins;
    font-size: 22px;
    font-weight: bold;
    line-height: 33px;
    color: #FFFFFF;
    text-align: right;
    margin-left: auto;
}

.checkout-cart-now {
    width: 336px;
    height: 64px;
    border-radius: 100px;
    background-color: #F3F4FF;
    border: 0px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.cart-titles-grid h6 {

    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    text-align: left;
    color: #000000;
}

.cart-titles-grid {

    width: 180px;
}

.cart-titles-grid p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #676B5F;
}

.cart-product-container:hover {
    transform: scale(1.1);
    /* Zoom in effect */
}


.empty-cart-message {

    width: 855px;
    height: 100%;
}

@media (max-width: 480px) {
    .flexing-cart {

        display: flex;
        flex-direction: column;

        column-gap: 12%;
    }

    .cart-product-container {

        width: auto;
        margin-left: 1rem;
        margin-right: 1rem;
        height: 100%;
        padding: 1rem;
    }

    .cart-product-layout {

        display: flex;
        flex-direction: column;
        column-gap: 5%;
        align-items: center;
        row-gap: 1rem;
        transition: transform 0.2s ease;
        /* Smooth transition for the zoom effect */

    }
    .cart-titles-grid h6 {

        font-size: 22px;
        text-align: center;
    }

    .zabala {
     
        margin-left: 0.5rem;
    }

    .checkout-container-summary {
        position:static;
        top: 0% !important;
        /* Adjust this value as needed */
        background: #4C4B95;
        padding: 1.5rem;
        width: auto;
        height: 100%;
        border-radius: 24px;
        margin-bottom: 2.6rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .cart-wrapper {
        padding-left: 0rem;
        width: 100%;
        padding-bottom: 0rem;
       
    }

    .checkout-cart-now {
        width: 100%;
      
    }
    .clear-cart-container {

        margin-left: auto;
        padding-right: 1rem;
    }

    .cart-product-container:hover {
        transform: none;
        /* Zoom in effect */
    }


    .empty-cart-message {

        width: 100%;
        height: 100%;
    }
    
}


@media only screen and (min-width: 768px) and (max-width: 1366px) {


    .flexing-cart {

        display: flex;
        flex-direction: column;
        column-gap: 12%;
    }

    .cart-product-container:hover {
        transform: none;
        /* Zoom in effect */
    }
    .cart-product-container {

        width: auto;
        margin-left: 1rem;
        margin-right: 1rem;
        height: 100%;
        padding: 1rem;
    }

    .cart-product-layout {

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        column-gap: 5%;
        align-items: center;
        row-gap: 1rem;
        transition: transform 0.2s ease;
        /* Smooth transition for the zoom effect */
    
    }


    .cart-titles-grid h6 {

        font-size: 22px;
        text-align: center;
    }

    .zabala {
     
        margin-left: 0.5rem;
    }

    .checkout-container-summary {
        position:static;
        top: 0% !important;
        /* Adjust this value as needed */
        background: #4C4B95;
        padding: 1.5rem;
        width: auto;
        height: 100%;
        border-radius: 24px;
        margin-bottom: 2.6rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .cart-wrapper {
        padding-left: 0rem;
        width: 100%;
        padding-bottom: 0rem;
       
    }

    .checkout-cart-now {
        width: 100%;
      
    }
    .clear-cart-container {

        margin-left: auto;
        padding-right: 1rem;
    }

    .counter-value-2 {
        width: 40px;

    }





}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
 
    .flexing-cart {

        display: flex;
        column-gap: 5%;
    }


    .cart-product-container {

        width: auto;
        margin-left: 1rem;
        margin-right: 1rem;
        padding: 1rem;
    }

    .cart-product-layout {

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        column-gap: 0.5rem;
        align-items: center;
        row-gap: 1rem;
        transition: transform 0.2s ease;
    
    }

    .counter-value-2 {
        width: 30px;

    }

    .cart-titles-grid h6 {
        font-size: 18px;
        text-align: center;
    }


}
