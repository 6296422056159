.Wrapper-Log {

    width: 100%;
    padding-top: 0rem;
    height: 100%;
}

.Logo-sec {

    padding-top: 3rem;
    display: flex;
    justify-content: center;
    margin-left: -23rem;
}

.Flexing-Login-sec {

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5%;

}

.left-log-data .left-log-data {

    padding-top: 2rem;

}


.left-log-data .left-log-data h6 {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    letter-spacing: -2px;
    text-align: center;
    color: #11021D;
}

.left-log-data .left-log-data p {

    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #676B5F;

}

.btns-left-sec-log-list {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.btns-left-sec-log-list span {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    color: #676B5F;

}

.btns-left-sec-log-list p {
    margin-top: 1rem;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #676B5F;


}


.LoG-btn {
    margin-top: 1rem;
    width: 505px;
    height: 48px;
    background: #8129D9;
    border-radius: 64px;
    color: #FAFAFA;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    border: 0px;

}

.sign-btn{

    margin-top: 1rem;
    width: 505px;
    height: 48px;
    background: #8129D9;
    border-radius: 64px;
    color: #FAFAFA;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    border: 0px;


}

.Google-btn {
    margin-top: 1rem;
    width: 505px;
    height: 48px;
    background-color: white;
    border: 1px solid #DCDCDC;
    border-radius: 64px;
    color: #11021D;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
}

.icon-img {

    margin-right: 0.3rem !important;
}

.form-signin-1 {

    display: flex;
    justify-content: center;
    align-items: center;


}

.form-signin-2{

    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

}


.input-customize {


    position: relative;


}




.input-customize input {

    background-color: #EFF0EC;
    width: 100%;
    /* Make input responsive */
    max-width: 505px;
    /* Fix width to 505px */
    height: 48px;
    border-radius: 8px;


}

.input-customize .show-password-icon-2 {
    position: absolute;
    top: 53%;
    right: 3%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px;
    color: black;
}



.third-sec-blue {


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.third-sec-blue .forgetpass-new {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #8129D9;
    text-decoration-line: underline;


}

.third-sec-blue .forgetpass-new {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #8129D9;
    text-decoration-line: underline;
}

.error-message {

    display: flex;
    justify-content: center;
    margin-top: 1rem;
    text-align: start;
    color: red;

}

.third-sec-blue .dont-have-new {

    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: center;
    color: #676B5F;
    margin-top: 0.5rem;

}


.third-sec-blue .purple-sign {

    color: #8129D9;
    text-decoration-line: underline;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
}

.input-phone {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.input-phone .react-tel-input .form-control {

    width: 505px !important;
    height: 48px !important;
    padding-left: 55px;
    margin: 0px !important;
    background-color: #EFF0EC !important;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
    font-size: .9rem;
    font-family: Poppins;
    color: #676B5F;

}

.input-phone .react-tel-input {
    justify-content: center;
    align-items: center;
    width: auto;
}

.input-phone .react-tel-input .selected-flag {
    width: 45px !important;
    background-color: #EFF0EC !important;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;

}

.third-sec-blue {


    padding-bottom: 1rem;
}




.cover-img {


    width: 100%;
    height: 100%;

}


.cover-img img {

    width: 100%;
    min-height: auto;
    height: auto;
    object-fit: fill;
    overflow-x: scroll;

}

.Wrapper-Log{

    --bs-gutter-x:0rem !important;

}

.login-slider-img {
    width: 400px !important;
    height: auto !important;

}

.image-slider-wrapper {
    width: 400px;
    height: 300px !important;
}

.image-slider-wrapper .swiper-wrapper {

    height: 300px !important;
    padding-bottom: 0rem !important;

}


.login-pop-up-img-container {

    display: flex;
    justify-content: center;
    align-items: center;

}


.login-popup-h6 {

    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    padding-top: 1rem;
}



@media (max-width: 480px) {

    .Flexing-Login-sec {

        display: flex;
        flex-direction: column-reverse;

    }

    .inputs-log-sec2{

        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;
    
    }
    


    .cover-img img {

        width: 100%;
        height: 100%;
        padding-top: 1rem;

    }

    .LoG-btn {
        margin-top: 1rem;
        width: 300px !important;
        height: 48px;
        background: #8129D9;
        border-radius: 64px;
        color: #FAFAFA;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
        border: 0px;

    }

    .Google-btn {
        margin-top: 1rem;
        width: 100%;
        height: 48px;
        background-color: white;
        border: 1px solid #DCDCDC;
        border-radius: 64px;
        color: #11021D;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
    }

    .left-log-data .left-log-data {

        padding-top: 1rem;

    }

    .show-password-icon {
        color: black;
        position: absolute;
        right: 6%;
        top: 53%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .third-sec-blue {


        padding-bottom: 3rem;
    }



    .react-tel-input .form-control {

        width: 100% !important;
        height: 48px !important;
        padding-left: 55px;
        margin: 0px 0px 0px;
        border-radius: 8px !important;
        background-color: #EFF0EC;
        border: var(--bs-border-width) solid var(--bs-border-color) !important;
        font-size: .9rem;
        font-family: Poppins;
        color: #676B5F;

    }

    .input-phone .react-tel-input .form-control {
        width: 100% !important;
        height: 48px !important;
        padding-left: 55px;
        margin: 0px 0px 0px;
        border-radius: 8px !important;
        background-color: #EFF0EC;
        border: var(--bs-border-width) solid var(--bs-border-color) !important;
        font-size: .9rem;
        font-family: Poppins;
        color: #676B5F;


    }

    

    .react-tel-input {
        justify-content: center;
        align-items: center;
        width: 100%;
    }


    .Logo-sec {

        padding-top: 1rem;
        display: flex;
        justify-content: left;
        margin-left: 0rem;
    }


    .left-log-data .left-log-data h6 {
        font-family: Poppins;
        font-size: 40px;

    }


    .input-wrapper input {
        background-color: #EFF0EC;
        width: 100%;
        height: 48px;
        border-radius: 8px;
        justify-content: center;
        align-items: center;

    }

    .input-customize .form-control {

        background-color: #EFF0EC;
        width: 100% !important;
        height: 48px;
        border-radius: 8px;
   


    }

 
    .inputs-log-sec2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .input-customizerelative {
        display: block;

    }

    .login-slider-img {
        width: 300px !important;
        height: auto !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .image-slider-wrapper {
        width: 300px;
        height: 200px !important;

    }

    .image-slider-wrapper .swiper-wrapper {

        height: 180px !important;
        padding-bottom: 2rem;

    }


    .sign-btn{

        margin-top: 1rem;
        width: 300px !important;
        height: 48px;
        background: #8129D9;
        border-radius: 64px;
        color: #FAFAFA;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
        border: 0px;
    
    
    }


}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .Flexing-Login-sec {

        display: flex;
        flex-direction: column-reverse;

    }


    .LoG-btn {
        width: 100%;

    }

    .Google-btn {
        margin-top: 1rem;
        width: 100%;

    }

    .Logo-sec {

        padding-top: 3rem;
        display: flex;
        justify-content: center;
        margin-left: 0rem;
    }

    .input-customize input {

        background-color: #EFF0EC;
        width: 100% !important;
        height: 48px;
        border-radius: 8px;
        justify-content: center;
        align-items: center;

    }

    .third-sec-blue {


        padding-bottom: 3rem;
    }

    .show-password-icon {
        color: black;
        position: absolute;
        right: 6%;
        top: 53%;
        transform: translateY(-50%);
        cursor: pointer;
    }



    .input-customizerelative {
        display: flexbox;
        width: 100%;
        justify-content: none;
    }

    .react-tel-input .form-control {

        width: 100%;
        height: 48px !important;
        padding-left: 55px;
        margin: 0px 0px 0px;
        border-radius: 8px !important;
        background-color: #EFF0EC;
        border: var(--bs-border-width) solid var(--bs-border-color) !important;
        font-size: .9rem;
        font-family: Poppins;
        color: #676B5F;

    }

    .input-wrapper input {
        background-color: #EFF0EC;
        width: 100% !important;
        height: 48px;
        border-radius: 8px;
        justify-content: safe;
        align-items: start;

    }

    .react-tel-input {
        justify-content: center;
        align-items: center;
        width: 100%;
    }

}


@media screen and (max-width: 1400px) {
 
    .cover-img {


        width: 100%;
        height: 100%;
    
    }
    
    .cover-img img {
    
        width: 100%;
        height: 100%;
        object-fit: fill;
        overflow-x: scroll;
        padding: 0px;
    }

}