.get-your-card-btn-three-new{
    width: 177px;
    height: 58px;
    border-radius: 16px;
    background: linear-gradient(180deg, #A36AB9 18.5%, #776AB9 63.5%, #576EE0 100%);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #FAFAFA;
    border: 0px;
}

.profile-number-three-theme{


    width: 494px;
height: auto;
background: linear-gradient(90deg, #F5DDFF 0%, #D6DDFF 49.5%, #F8E9FF 100%);
box-shadow: 0px 4px 4px 0px #00000029;
border-radius: 16px;

}


.flexing-white-containers {
    padding-top: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    position: relative;
  }
  
  .flexing-white-containers .box {
    width: 446px;
    height: 188px;
    background: #FDFDFD99;
    border-radius: 24px;
    box-shadow: 2px 4px 4px 0px #0000001F;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .box h5 {


    font-family: Roboto;
    font-weight: 500;
    font-size: 26px;
    color: #18061E;
    text-align: center;
    
    }


    .box .icons{

        width: auto;

    }
    
    .box p {
    
        font-family: Roboto;
        font-weight: 400;
        font-size: 20px;
        line-height: 23.44px;
        letter-spacing: 2%;
        text-align: center;
        color: #515E69;
        
    
    }
  
  .flexing-white-containers .overlay {
    display: flex;
    gap: 10px;
    position: absolute;
  }
  
  .flexing-white-containers .overlay-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 3.4rem;
  }


  .flexing-white-containers  .overlay-img2{
    width: 140px;
    height: 140px;
    border-radius: 50%;

  }

  .data-container-new-three {


    width: 447px;
    height: 58px;
    border-radius: 16px;
    border-bottom-width: 3px;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-left: 16px;
    background: #FDFDFD99;
    box-shadow: 0px 4px 4px 0px #00000029;


}
  
.images-under-img{
  padding-top: 1rem;
  display: flex;
  column-gap: 1rem;

}

.flexing-icons-section-theme-3{

  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  column-gap: 1rem;

}

.ul-for-attachment-new-theme-three {


  list-style-type: none;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 2rem;
  padding-top: 1.2rem;


}

.attachment-container-element-new-theme-three {

  width: 447px;
  height: 58px;
  border-radius: 16px;
  border-bottom-width: 3px;
  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 16px;
  background: #FDFDFD99;
  box-shadow: 0px 4px 4px 0px #00000029;
  display: flex;
  justify-content: space-between;

}

.ul-for-attachment-new-theme-three .paragraph-for-attachment-new-theme-three {

  width: 80px;
  text-align: center;
  border: 0px;
  height: 38px;
  padding: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 55.1px;
  font-weight: 700;
  font-size: 16px;
  color: white;
  background: linear-gradient(180deg, #A36AB9 18.5%, #776AB9 63.5%, #576EE0 100%);
  padding-top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80px;
  /* Adjust to fit 3 characters */

}

.ul-for-attachment-new-theme-three .btn-theme-one-purble-theme-three {
  width: 142px;
  height: 32px;
  border-radius: 16px;
  padding-top: 4px;
  padding-right: 16px;
  padding-bottom: 4px;
  padding-left: 16px;
  background: linear-gradient(180deg, #A36AB9 18.5%, #776AB9 63.5%, #576EE0 100%);
  border: 0px;
  color: white;
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  line-height: 19.36px;
  letter-spacing: 0%;
}

.ul-for-attachment-new-theme-two{


  list-style-type: none;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 2rem;
  padding-top: 1.2rem;
}




@media (max-width: 480px) {

  .flexing-white-containers .box {
    width: 95%;
    height: 188px;
    background: #FDFDFD99;
    border-radius: 24px;
    box-shadow: 2px 4px 4px 0px #0000001F;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }


  .data-container-new-theme-three {


      width: 95%;



  }

  .profile-number-three-theme{


    width: 100%;
height: auto;
background: linear-gradient(90deg, #F5DDFF 0%, #D6DDFF 49.5%, #F8E9FF 100%);
box-shadow: 0px 4px 4px 0px #00000029;
border-radius: 16px;

}



  .get-your-card-btn-three-new{

    width: 100%;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
}

  .attachment-container-element-new-theme-three {

      width: 95%;

  }

  .data-container-new-three {


    width: 95%;
    height: 58px;
    border-radius: 16px;
    border-bottom-width: 3px;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-left: 16px;
    background: #FDFDFD99;
    box-shadow: 0px 4px 4px 0px #00000029;


}



  
  

}