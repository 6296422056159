.Myprofile-sec {}

.flexing-my-profile-sec {

    padding-top: 1rem;
    display: flex;


}

.flexing-my-profile-sec-List {

    list-style-type: none;


}

.flexing-my-profile-sec-List li {
    margin-bottom: 1.5rem;
    cursor: pointer;
    color:#676B5F;
    display: flex;
    justify-content: center;
    width: 200px;
    padding: 0.5rem;
    

}

.flexing-my-profile-sec-List li.active {
    background-color: white;
    color: black;
    /* Adjust text color if needed */
    font-weight: bold;
    border-radius: 10px 0px 0px 10px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: #705198;
    box-shadow: -6px 0px 8px 0px #0000001A;
}


.profile-wrap-container {

    display: flex;

}

.profile-img-sec {

    background-color: #E7E7E7;
    width: 212px;
    height: 431px;
    border-bottom-left-radius: 24px;
}

.profile-card {

    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    justify-content: center;
    align-items: center;

}

.profile-card h6 {

    margin-top: 1rem;

}

.profile-card p {

    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 10px;
    margin-top: 0.3rem;
    color: #000000;


}


.profile-card span {

    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #000000;


}

.profile-data-sec {

    width: 848px;
    height: 431px;
    padding: 1rem;
    border-radius: 0px 24px 24px 0px;
    background: #FFFFFF;


}

.Rows-data {


    display: flex;
    flex-wrap: wrap;
    /* Allows wrapping of elements onto a new row */
    justify-content: space-between;
    column-gap: 5%;

}

.edit-btn-profile {


    float: right;

}


.edit-btn-profile button {

    width: 268px;
    height: 48px;
    background: #705198;
    color: white;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    border-radius: 50px;
    border: 0px;
    margin-top: 1rem;


}

.single-personal-data-layout .form-control:disabled{


    background-color: #F6F6F6 !important;
}

.single-personal-data-layout .form-control {

    background-color: #E7E7E7 !important;

}


.single-personal-data-layout .react-tel-input .form-control {
    background-color: #F6F6F6;
    width: 100% ;
    height: 38px ;
    margin: 0px ;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
    font-size: .91rem;
    font-family: Poppins;
    color: #676B5F;
}

.single-personal-data-layout .react-tel-input {
    justify-content: center;
    align-items: center;
    width: auto;
}

.single-personal-data-layout .react-tel-input .selected-flag {
    width: 45px !important;
    background-color: #EFF0EC !important;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;

}

.Rows-data-address{

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16%;

}

.rounded-profile-img{

    width: 100px;
    height: 100px;
    border-radius: 50px ;
    object-fit: cover;
    

}

.profile-card-img{

    position: relative;
    display: inline-block;

}

.edit-icon-profile-img{

    position: absolute;
    top: 0.8rem;
    right: -0.1rem;
    color: #333; /* Icon color */
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
}

.light-effect {
    animation: glow 1s ease-out;
  }
  
  @keyframes glow {
    0% {
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.8);
    }
    50% {
      box-shadow: 0 0 15px rgba(0, 123, 255, 1);
    }
    100% {
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.8);
    }
  }
  
  
@media (max-width: 480px) {

    .flexing-my-profile-sec {

        padding-top: 4rem;
        display: flex;
        flex-direction: column;
    
    }
    
    .flexing-my-profile-sec-List {
    
        list-style-type: none;
        display: flex;
        width: 100%;
        padding-left: 0px;
        }

    .flexing-my-profile-sec-List li {
        margin-bottom: 0rem;
        cursor: pointer;
        color:#676B5F;
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0.5rem;
        font-size: 15px;

    }
    
    .flexing-my-profile-sec-List li.active {
        background-color: white;
        color: black;
        font-weight: bold;
        border-radius: 10px 10px 0px 0px;
        font-family: Poppins;
        font-size: 15px;
        font-weight: 500;
        line-height: 21px;
        color: #705198;
        box-shadow: -6px 0px 8px 0px #0000001A;
        
    }


    .profile-wrap-container {
        margin-top: -1rem;
        display: flex;
        flex-direction: column;
    
    }

    .profile-img-sec {

        background-color: #E7E7E7;
        width: 100%;
        height: auto;
        border-bottom-left-radius: 24px;
        border-radius: 0px 0px 24px 24px;
        margin-bottom: 1rem;
    }

    .profile-data-sec {

        width: 100%;
        height: auto;
        padding: 1rem;
        border-radius: 24px 24px 24px 24px;
        background: #FFFFFF;
    
    
    }
    
    .edit-btn-profile {


        float:none;
    
    }
    .edit-btn-profile button {

        width: 100%;
        height: 48px;
        background: #705198;
        color: white;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        border-radius: 50px;
        border: 0px;
        margin-top: 1rem;
    
    
    }

    .Rows-data-address{

        display: flex;
        flex-direction: column;
        grid-template-columns: 1fr 1fr;
        column-gap: 16%;
    
    }
    
}

.floating-mycards{

    display: flex;
    justify-content: flex-end;

}

.floating-mycards button{

    width: auto;
    padding: 1rem;
    height: 48px;
    background: #705198;
    color: white;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 0px;
    border-radius: 50px;
    border: 0px;
    margin-top: 1rem;


}


@media (min-width: 768px)and (max-width: 1366px) {

    .flexing-my-profile-sec {

        padding-top: 4rem;
        display: flex;
        flex-direction: column;
    
    }
    
    .flexing-my-profile-sec-List {
    
        list-style-type: none;
        display: flex;
        width: 100%;
        padding-left: 0px;
        }

    .flexing-my-profile-sec-List li {
        margin-bottom: 0rem;
        cursor: pointer;
        color:#676B5F;
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0.5rem;
        font-size: 15px;

    }
    
    .flexing-my-profile-sec-List li.active {
        background-color: white;
        color: black;
        font-weight: bold;
        border-radius: 10px 10px 0px 0px;
        font-family: Poppins;
        font-size: 15px;
        font-weight: 500;
        line-height: 21px;
        color: #705198;
        box-shadow: -6px 0px 8px 0px #0000001A;
        
    }


    .profile-wrap-container {
        margin-top: -1rem;
        display: flex;
        flex-direction: column;
    
    }

    .profile-img-sec {

        background-color: #E7E7E7;
        width: 100%;
        height: auto;
        border-bottom-left-radius: 24px;
        border-radius: 0px 0px 24px 24px;
        margin-bottom: 1rem;
    }

    .profile-data-sec {

        width: 100%;
        height: auto;
        padding: 1rem;
        border-radius: 24px 24px 24px 24px;
        background: #FFFFFF;
    
    
    }
    
    .edit-btn-profile {


        float:none;
    
    }
    .edit-btn-profile button {

        width: 100%;
        height: 48px;
        background: #705198;
        color: white;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        border-radius: 50px;
        border: 0px;
        margin-top: 1rem;
    
    
    }

    .Rows-data-address{

        display: flex;
        flex-direction: column;
        grid-template-columns: 1fr 1fr;
        column-gap: 16%;
    
    }

    .Rows-data{

        display: flex;
        flex-direction: column;

    }
    
}
