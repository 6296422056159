.New-Profile-2-contanier {
    width: 500px;
    /* Full width */
    height: auto;
    /* Keeps the height */
    border-radius: 16px;
    background-image: url("/webapps/tippytap/src/Assets/pppppppp.png");
    background-repeat: no-repeat;
    background-position: center;
    /* Centers the background image */
    background-size: cover;
    /* Ensures the image covers the full area */
    box-shadow: 0px 4px 4px 0px #00000029;
}

.personaldata-section-theme-2 {
    margin-top: 12rem;
    background: #FBFBFB;
    height: 100%;
    border-top-left-radius: 64px;
    border-top-right-radius: 64px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    position: relative;
    padding-bottom: 1rem;
}

.profile-img-2 {
    /* position: absolute;
    left: 35%;
    top: 5%;
    transform: translateY(-50%); */
    margin-top: -6rem;
    width: 160px;
    height: 160px;
    border-radius: 50%;

}

.icons-left-theme-2 {
    display: flex;
    column-gap: 2rem;
    margin-top: 2rem;

}

.icons-right-theme-2 {
    display: flex;
    column-gap: 2rem;
    margin-top: 2rem;

}



.flexing-four-icons-img {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.social-links-new-theme-2 {



    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
}



.social-links-new-theme-2 h5 {


    font-family: Roboto;
    font-weight: 500;
    font-size: 25px;
    color: #020928;
    text-align: center;

}

.social-links-new-theme-2 p {

    font-family: Roboto;
    font-weight: 400;
    font-size: 20px;
    line-height: 23.44px;
    letter-spacing: 2%;
    text-align: center;
    color: #515E69;

}

.data-new-containers-new-theme-two {

    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;

}

.data-container-new-theme2 {
    width: 447px;
    height: 58px;
    border-radius: 16px;
    border-bottom-width: 3px;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-left: 16px;
    background-color: #FBF8FC;

}

.flexing-icon-with-cv {

    display: flex;
    column-gap: 2rem;

}

.attachment-container-element-new-theme-two {

    width: 447px;
    height: 58px;
    border-radius: 16px;
    border-bottom-width: 3px;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-left: 16px;
    background-color: #FBF8FC;
    display: flex;
    justify-content: space-between;

}

.ul-for-attachment-new-theme-two .paragraph-for-attachment-new-theme-two {

    width: 80px;
    text-align: center;
    border: 0px;
    height: 38px;
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 55.1px;
    font-weight: 700;
    font-size: 16px;
    color: white;
    background: linear-gradient(180deg, #5168D8 0%, #3E4FA5 35%, #2B3772 80%);
    padding-top: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 80px;
    max-width: fit-content;
    /* Adjusts to content width */

    /* Adjust to fit 3 characters */

}

.ul-for-attachment-new-theme-two .btn-theme-one-purble-theme-two {
    width: 142px;
    height: 32px;
    border-radius: 16px;
    padding-top: 4px;
    padding-right: 16px;
    padding-bottom: 4px;
    padding-left: 16px;
    background: linear-gradient(180deg, #5168D8 0%, #3E4FA5 35%, #2B3772 80%);
    border: 0px;
    color: white;
    font-family: Inter;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.36px;
    letter-spacing: 0%;
}

/* .data-container-new-theme-two {


    width: 447px;
    height: 58px;
    border-radius: 16px;
    border-bottom-width: 3px;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-left: 16px;
    background-color: #FBF8FC;
} */

.relative-div-icon {

    position: relative;
}


.get-your-card-btn-theme-two {

    width: 196px;
    height: 58px;
    border-radius: 16px;
    background: #5168D8;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #FAFAFA;
    border: 0px;
    box-shadow: 2px 4px 4px 0px #0000001F;
}

.width-of-link {
    display: inline-block; /* Ensures the width is respected */
    max-width: 18ch; /* Limits to 18 characters */
    white-space: nowrap; /* Prevents wrapping */
    overflow: hidden; /* Hides overflow */
    text-overflow: ellipsis; /* Adds "..." when text is cut off */
  }
  

@media (max-width: 480px) {

    .personaldata-section-theme-2 {
        margin-top: 12rem;
        background: #FBFBFB;
        width: 100%;
        height: 100%;
        border-top-left-radius: 64px;
        border-top-right-radius: 64px;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        position: relative;
        padding-bottom: 1rem;
    }

    .New-Profile-2-contanier {
        width: 100%;

    }

    .data-container-new-theme2 {
        width: 95%;
    }

    .attachment-container-element-new-theme-two {

        width: 95%;

    }

    .profile-img-2 {
        position: absolute;
        left: -4.8rem;
        top: 5%;
        transform: translateY(-50%);
        width: 160px;
        height: 160px;

    }

    .get-your-card-btn-theme-two {

        width: 100%;
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .flexing-four-icons-img {

        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        column-gap: 1rem;

    }


}