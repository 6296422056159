.Partner-Sec {
  padding: 3rem;
  margin-top: 0rem;
  padding-bottom: 0rem;
  position: relative;
  
}

.Partner-sec-wrap-color{

  background-color: #080F2A;
}

.white-background-color {

  background-color: #fafafa;

}

.Partner-titles h6 {

  color: #FFFFFF;
  font-family: Poppins;
font-size: 56px;
font-weight: 700;
line-height: 58.8px;
letter-spacing: -1.1200000047683716px;
text-align: center;

}




.Partner-titles p {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -1.1200000047683716px;
  text-align: center;
  color: #FFFFFF;

}



.image-container {
  min-width: 300px;
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  border-radius: 100px;
  padding: 2rem;
}

.image-container img {
  width: auto;
  height: 80px;
}



.partner-item-container img {
  width: 156px;
  height: 78px;
}

.partner-item-container2 {

  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.7rem;
}



.swiperpartners.swiper-wrapper {

  padding-bottom: 0rem;
}

.Partner-Sec .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
}


.swiperpartners .swiper-wrapper {
  display: flex;
  animation: continuousSlide 30s linear infinite;
}

@keyframes continuousSlide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}


@media (max-width: 480px) {


  .Partner-titles h5 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 54px;
    text-align: center;
    color: rgba(0, 0, 0, 1);
  }

  .image-container img {
    width: 130px;
    height: 50px;
  }



  .image-container img {
    width: auto;
    height: 60px;
  }

  .image-container {
    min-width: 275px;
    width: 100%;
    height: 130px ;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    border-radius: 50px;
    padding: 2rem;
  }


.swiperpartners .swiper-wrapper {
  display: flex;
  animation: continuousSlide 10s linear infinite;
}

@keyframes continuousSlide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-500%);
  }
}

.Partner-Sec .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

}




@media only screen and (min-width: 768px) and (max-width: 1024px) {






  .image-container img {
    width: auto;
    height: 50px;
  }

  .image-container {
    min-width: 180px;
    width: 100% ;
    height: 100px ;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    border-radius: 50px;
    padding: 2rem;
  }

  .swiper-slide-auto {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

}