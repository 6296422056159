.accordion-wrap {

  width: 95% !important;
  padding-left: 6.3rem;

}

.accordion-wrap .accotion {

  width: 100%;
}

.accordion-wrap .accordion-button {
  background-color: transparent !important;
  border: none;
  padding-left: 0;
  border-radius: 8px;
  font-size: 20px !important;
  font-family: Poppins;
  font-weight: 700;
  color: #543769	!important;
}

.accordion-wrap.accordion-button:focus, .accordion-button:active {
  box-shadow: none !important; /* Removes the blue outline */
  outline: none !important; /* Removes any default outline */
  background-color: transparent; /* Optional: set background color */
}



.accordion-wrap .accordion-button:focus,
.accordion-wrap .accordion-button.active {
  color: #543769	!important;
}

.accordion-wrap .accordion-header:focus,
.accordion-wrap .accordion-header.active {
  color: #543769	!important;
}

.accordion-wrap .accordion-button::after {
  display: none;
}

.accordion-wrap .accordion-item {
  border-radius: 22px !important;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
  width: 100% !important;
  /* background-color: #b984c3; */

}


.accordion-wrap .accordion-body {
  padding-left: 15px;
  padding-right: 15px;
  border-top: 1px solid #ccc;
  padding-bottom: 1rem;

}

.accordion-wrap .accordion-button:focus,
.accordion-wrap .accordion-button:active {
  box-shadow: none;
  border-color: none !important;
  outline: none;
}

.accordion-wrap .accordion-button {
  border: none;
}

.accordion-wrap.accordion-button:not(.collapsed) {
  background-color: transparent;
}

.card-templete {

  width: 400px;
  height: 603px;
  padding: 24px 29px 24px 29px;
  border-radius: 20px;
  border: 1px solid #6E24AF;
  position: relative;
  /* Relative positioning for child elements */


}

.card-tippy {
  width: 100%;

}

.price-title-flex {

  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}

.price-title-flex h6 {

  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  padding: 0px;

}

.price-title-flex p {

  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
}

.price-title-flex span{

  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.card-text {
  margin-bottom: 0px;

}

.counter-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.decrement-btn {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  color: #644200;
  background-color: white;
  border: 1px solid #644200;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.46px;
  padding: 0px;
}

.INcrement-btn {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  color: #644200;
  background-color: white;
  border: 1px solid #644200;
  font-size: 18px;
  font-weight: 500;
  line-height: 10px;
}

.counter-value {
  width: 103px;
  height: 30px;
  border-radius: 50px;
  border: 0px solid #4A27DF;
  background-color: #F5F5F5;
  font-family: Poppins;
  font-size: 20px;
  padding-top: 0rem;
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  display: inline-block;
}


.counter-value-input {
  width: 103px;
  height: 30px;
  border-radius: 50px;
  border: 0px solid #4A27DF;
  background-color: #F5F5F5;
  font-family: Poppins;
  font-size: 20px;
  padding-top: 0rem;
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  display: inline-block;
  justify-content: center;
  align-items: center;
}

.card-btns-sec {

  display: flex;
  column-gap: 5%;

}



.order-now-btn-2 {

  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1599999964237213px;
  text-align: center;
  color: white;
  width: 163px;
  height: 56px;
  border: 0px;
  border-radius: 25px;
  background-color: #6E24AF;
  
}

.add-to-card-btn {
  width: 163px;
  height: 56px;
  border-radius: 25px;
  border: 1px solid #6E24AF;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1599999964237213px;
  text-align: center;
  background-color: white;
  transition: all 0.3s ease;

}

.add-to-card-btn img {
  margin-right: 8px;
}

.add-to-card-btn:disabled {
  background-color: #b984c3;
  border: 1px solid #770737	;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  width: 163px;
  border-radius: 25px;
  height: 56px;
  color: white;
}

.pulse {
  animation: pulse 0.5s ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.tippy-card-grid {

  display: flex;
  flex-wrap: wrap;
  /* Allows items to wrap onto the next line */
  column-gap: 5%;
  justify-content: center;
  row-gap: 2rem;
  align-items: center;
}

.tippy-card-grid-single{

  display: flex;
  /* Allows items to wrap onto the next line */
  column-gap: 5%;
  row-gap: 2rem;
  padding-left: 1rem;
  
}


.animation-sec {
  width: 330px;
  height: 310px;
  border-radius: 300px;
  border: 20px solid #8D86A4;
  position: relative;
}

.animation-sec .card-tippy-front {
  position: absolute;
  z-index: 2;
  /* Front image appears on top */
  width: 350px;
  transition: transform 0.6s ease;

  margin-left: -2rem;
  /* Add right margin */
  margin-top: -1rem;
  /* Add right margin */

}

.card-tippy-front-2 {
  position: absolute;
  z-index: 2;
  /* Front image appears on top */
  transition: transform 0.6s ease;
  margin-left: 3.5rem;

}

.card-tippy-front-hor {

  position: absolute;
  z-index: 2;
  /* Front image appears on top */
  margin-left: -1rem;
  margin-top: -1rem;
  transition: transform 0.6s ease;
  /* Smooth transition for movement */

}

.card-tippy-back-hor {

  position: absolute;
  z-index: 1;
  margin-left: 7rem;
  transition: transform 0.6s ease;
  /* Smooth transition for movement */
  width: 174px;

}

.card-templete:hover .card-tippy-front-hor {
  transform: rotate(20deg) scale(1.1);
  /* Rotate and zoom in on hover */
  z-index: 3;
  /* Optionally, bring it to the very front on hover */

}

.card-templete:hover .card-tippy-front-2 {
  transform: rotate(0deg) scale(1.1);
  /* Rotate and zoom in on hover */
  z-index: 3;
  /* Optionally, bring it to the very front on hover */

}

.animation-sec .card-tippy-back {
  position: absolute;
  z-index: 1;
  margin-left: -1rem;
  margin-top: 2rem;
  width: 350px;
  transition: transform 0.6s ease;
  /* Smooth transition for movement */

}

.card-templete:hover .card-tippy-front {
  transform: rotate(20deg) scale(1.1);
  /* Rotate and zoom in on hover */
  z-index: 3;
  /* Optionally, bring it to the very front on hover */
}


.card-templete:hover .card-tippy-back {
  transform: translateY(-20px);
  /* Move up and zoom in on hover */
}

.card-templete:hover .card-tippy-back-hor {
  transform: translateX(-20px);
  /* Move up and zoom in on hover */
}

@keyframes zoom-in-rotate {
  0% {
    transform: rotate(0deg) scale(1);
    /* Start at original size and rotation */
  }

  100% {
    transform: rotate(20deg) scale(1.2);
    /* End at rotated and zoomed-in size */
  }
}



.accordion-button .arrow {
  margin-left: auto; /* Aligns the arrow to the right */
  transition: transform 0.3s ease;
}

.accordion-button:not(.collapsed) .arrow {
  transform: rotate(90deg); /* Rotates the arrow when the accordion is expanded */
}

.accordion-button.collapsed .arrow {
  content: '\25BA'; /* Right arrow when collapsed */
}

.accordion-button:not(.collapsed) .arrow::before {
  content: '\25BC'; /* Down arrow when expanded */
}


@media (max-width: 480px) {

  .accordion-wrap {

    width: 100% !important;
    padding-left: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;

  }

  .card-templete {

    width: 330px;
    padding:1rem;
    height: auto;

  }

  .tippy-card-grid-single{

    display: flex;
    /* Allows items to wrap onto the next line */
    justify-content: center;
    column-gap: 5%;
    row-gap: 2rem;
    padding-left: 0rem;
    
  }


  .animation-sec .card-tippy-front {
    position: absolute;
    z-index: 2;
    /* Front image appears on top */
    width: 100%;
    transition: transform 0.6s ease;

    margin-left: -2rem;
    /* Add right margin */
    margin-top: 1rem;
    /* Add right margin */

  }

  .animation-sec .card-tippy-back {
    position: absolute;
    z-index: 1;
    margin-left: 0.5rem;
    margin-top: 3rem;
    width: 100%;
    transition: transform 0.6s ease;
    /* Smooth transition for movement */

  }

  .card-tippy-front-hor {

    position: absolute;
    z-index: 2;
    /* Front image appears on top */
    margin-left: -1rem;
    margin-top: -2rem;
    transition: transform 0.6s ease;
    /* Smooth transition for movement */
    width: 100%;

  }

  .card-tippy-back-hor {
    position: absolute;
    z-index: 1;
    margin-left: 6rem;
    transition: transform 0.6s ease;
    /* Smooth transition for movement */
    width: 130px;
    margin-top:0.1rem;

  }

  .card-tippy-front-2 {
    position: absolute;
    z-index: 2;
    /* Front image appears on top */
    transition: transform 0.6s ease;
    margin-left: 3rem;
    margin-top: -0.8rem;
    width: 170px;
    height: auto;

  }

  .animation-sec {
    width: 100%;
    height: 280px;
    border-radius: 300px;
    border: 20px solid #8D86A4;
    position: relative;
  }

  .accordion-item {
    border-radius: 22px !important;
    padding-left: 0px !important;
    padding: 1rem !important;
    margin-bottom: 1rem;
    width: 100% !important;
    padding: 15px;
  }
  
  .card-btns-sec {
  
    display: flex;
    flex-direction:column;
    row-gap: 1rem;
    column-gap: 5%;
  
  }

  .order-now-btn-2 {
    width: 100%;
    height: 56px;
  }
  
  .add-to-card-btn {
    width: 100%;
    height: 56px;
  }

  .accordion-body {
    padding-left: 0px;
    padding-right: 0px;
    border-top: 1px solid #ccc;
    padding-bottom: 1rem;
  
  }


  .price-title-flex h6 {

    font-family: Poppins;
    font-size: 18px !important;
    font-weight: 500;
    line-height: 27px;
    padding: 0px;
    margin: 0px;
  
  }
  
  .price-title-flex p {
  
    font-family: Poppins;
    font-size: 21px !important;
    font-weight: 500;
    line-height: 27px;
  }
  
  .price-title-flex span{
  
    font-family: Poppins;
    font-size: 17px !important;
    font-weight: 400;
    line-height: 27px;
  }

  .add-to-card-btn:disabled {
    background-color: #b984c3;
    border: 1px solid #770737	;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    width: 100%;
    border-radius: 25px;
    height: 56px;
    color: white;
  }

}


@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .accordion-wrap {

    width: 100% !important;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom:3rem;

  }
    
}