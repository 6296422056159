.IMages-grid {
    display: flex;
    position: relative;
    padding-top: 15rem;
    transition: all 0.5s ease;
    margin-left: -6rem;
    margin-top: 3rem;

}



.IMages-grid .image1 img {
    z-index: 100;
    margin-left: 10rem;
    position: relative;
    /* Ensures z-index works */
    width: 200px;
    height: 180px;
    opacity: 1;
    transition: opacity 0.5s ease, transform 0.5s ease;
    /* Include transform for sliding effect */
}


.IMages-grid .image1 img.fade-out {
    opacity: 0;
    /* Fade out */
    transform: translateY(50px);
    /* Slide down */
}

.fade-in {
    opacity: 0;
    animation: fadeIn 3s forwards;
    /* Animation to fade in */
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}


.IMages-grid .image2 img {
    z-index: 50;
    position: relative;
    margin-left: -8rem;
    /* Move the second image behind the first */
    margin-top: -2rem;
    width: 200px;
    height: 180px;

}

.IMages-grid .image3 img {
    z-index: 30;
    position: relative;
    margin-left: -8rem;
    /* Move the second image behind the first */
    margin-top: -4rem;
    width: 200px;
    height: 180px;

}

.IMages-grid .image4 img {
    z-index: 29;
    position: relative;
    margin-left: -8rem;
    /* Move the second image behind the first */
    margin-top: -6rem;
    width: 200px;
    height: 180px;

}

.IMages-grid .image5 img {
    z-index: 28;
    position: relative;
    margin-left: -8rem;
    /* Move the second image behind the first */
    margin-top: -8rem;
    width: 200px;
    height: 180px;

}

.IMages-grid .image6 img {
    z-index: 27;
    position: relative;
    margin-left: -8rem;
    /* Move the second image behind the first */
    margin-top: -10rem;
    width: 200px;
    height: 180px;


}

.IMages-grid .image7 img {
    z-index: 26;
    position: relative;
    margin-left: -8rem;
    /* Move the second image behind the first */
    margin-top: -12rem;
    width: 200px;
    height: 180px;

}


@media (max-width: 480px) {

    .IMages-grid {
        display: none !important;
        position: relative;
        padding-top: 15rem;
        transition: all 0.5s ease;
        margin-left: -6rem;
        margin-top: 3rem;

    }



}


@media only screen and (min-width: 768px) and (max-width:1366) {
    .IMages-grid {
        display: flex;
        /* Ensure it's visible */
        flex-wrap: wrap;
        justify-content: center;
        /* Center images */
        padding-top: 10rem;
        /* Adjust padding */
        margin-left: -6rem;
        margin-top: 2rem;
    }

    .IMages-grid img {
        width: 150px;
        /* Adjust size */
        height: auto;
        margin-left: 0;
        margin-top: 0;
    }
    .IMages-grid .image7 img {
        z-index: 26;
        position: relative;
        margin-left: -10rem;
        /* Move the second image behind the first */
        margin-top: -12rem;
        width: 200px;
        height: 180px;
    
    }

}



/* @media screen and (max-width: 1024px) and (max-height: 1366px) {

    .IMages-grid {
        display: none !important;
        position: relative;
        padding-top: 15rem;
        transition: all 0.5s ease;
        margin-left: -6rem;
        margin-top: 3rem;
    
    }

}  */