.Wrap-Fully {

  background-color: #533668;

}

.flex-Welcomesec {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5%;
  padding-top: 10rem;
  padding-bottom: 2rem;
}

.Right-text {
  padding-top: 6rem;
}

.Right-text h6 {
  font-family: Poppins;
  font-weight: 700;
  font-style: normal;
  font-weight: bold;
  text-align: left;
  color: #FAFAFA;
  font-size: 44px;
}

.tippycardrightimg {
  width: 600px;
  height: 577px;
}

.Right-text p {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 28.45px;
  text-align: left;
  color:
    #FAFAFA;
}

@media (max-width: 480px) {

  .flex-Welcomesec {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 6rem;
  }
  .Right-text h6 {
    font-family: Poppins;
    font-weight: 700;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    color: #FAFAFA;
    font-size: 30px;
  }
  .Right-text p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 28.45px;
    text-align: center;
    color:
      #FAFAFA;
  }
  

  .Left-img-welcome-sec {

    margin-top: 1.5rem;
  }

  .Welcomesec {
    padding-top: 5rem;
  }

  .Left-img-welcome-sec img {

    width: 100%;
    height: auto;
    margin-top: 0rem;
  }

  .Right-text {
    padding-top: 0rem;
    margin-top: 3rem;
  }


}

@media only screen and (min-width: 768px) and (max-width: 1025px) {

  .Right-text h6 {
    font-family: Poppins;
    font-weight: 700;
    font-style: normal;
    font-weight: bold;
    text-align: left;
    color: #FAFAFA;
    font-size: 30px;
  }

  .flex-Welcomesec {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15%;
    padding-top: 10rem;
    padding-bottom: 2rem;
  }

 
  .tippycardrightimg {

    width: 100%;
    height: 100%;
  }

  .Right-text p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 28.45px;
    text-align: left;
    color:
      #FAFAFA;
  }

  .Right-text {
    padding-top: 0rem;
    margin-top: 2rem;
  }

}