.Elements-selection{


    padding-top: 10rem;
  
}

.back-ground-color{


    background: #EDEDED;

}

.Elements-selection-List{

    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding-left: 0px;
}

.Elements-selection-List li {
    background-color: #FFFFFF;
    cursor: pointer;
    width: 329px;
    height: 48px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #676B5F;
}

.Elements-selection-List li.active {
    background-color: #7B5AA7;
    color: #FFFFFF;
    border: 1px solid #7B5AA7;
    font-weight: 700;
  }


  .Elements-selection-List li.active img {
    filter: invert(100%) brightness(100) saturate(0); /* Converts the image to white */
  }


  .canvas-display{

    display: none;

  }

  .newicon-margin{

    margin-left: 10px;
  }



  @media (max-width: 480px) {

    .newicon-margin{
      width: 16px;
      height: 16px;
      margin-left: 2px;
    }

 
    /* .canvas-display{

        display: block;
        padding-top: 6rem;
      }

      .Elements-selection{
        padding-top: 0rem;
      } */
      .Elements-selection-List{

        display: flex;
        justify-content: space-between;
        list-style-type: none;
        column-gap: 1rem;
        padding-left: 0px;
        padding-top: -1rem;

    }

    .Elements-selection-List .mx-1 {
      display: none;
    }

    

    .Elements-selection-List li {
      background-color: #FFFFFF;
      cursor: pointer;
      width: 329px;
      height: 48px;
      border-radius: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #676B5F;
      font-size: 12px;
  }


  

  }


  @media screen and (min-width: 768px) and (max-width: 1024px) {
  
    .Elements-selection-List{

      display: flex;
      justify-content: space-between;
      list-style-type: none;
      column-gap: 1rem;
      padding-left: 0px;
  }
  
  
  }
