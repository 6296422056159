.background-container {

    background-color: #EDEDED;
    height: 100vh;

}

.flexing-guest-page {
    display: flex;
    justify-content: space-between;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 0rem;
    flex-direction: row;
}

.left-guest-content {
    padding: 5rem;

}

.left-guest-content h6 { 

    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    color: #000000;
    padding-top: 3rem;
    padding-left: 1rem;

}

.linkinput-class {


    padding-left: 1rem;
    padding-top: 2rem;


}

.linkinput-class label {

    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    padding-bottom: 1rem;

}


.linkinput-class input {

    width: 540px;

}

.btn-purple-guest {

    padding-left: 1rem;
    padding-top: 2rem;

}

.btn-purple-guest button{

    width: 540px;
    height: 48px;
    padding: 13px 8px 13px 8px;
    gap: 8px;
    border-radius: 64px;
    background: #8129D9;
    color: white;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    border: 0px;

}

.Btns-for-guest{


    display: flex;
    flex-direction: column;


}

.background-model-guest{

    background-image: url("/webapps/tippytap/src/Assets/Vector (8).png");
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; 
}

.background-model-login {

    background-image: url("/webapps/tippytap/src/Assets/Vector (8).png");
    background-size: contain; 
    background-repeat: no-repeat;
    background-position: center;


}

.center-logo-model{


    display: flex;
    justify-content: center;


}

.h6-for-guest-model{


font-family: Poppins;
font-size: 22px;
font-weight: 500;
line-height: 33px;
text-align: center;
padding: 2rem 0rem;


}

.Btns-for-guest{


    display: flex;
    flex-direction: column;
    justify-content: center;
    column-gap: 1rem;

}


.login-btn-guest{

    width: auto;
    height: 52px;
    border-radius: 64px;
    background: #8129D9;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FAFAFA;
    margin-bottom: 1rem;
    border: 0px;

}


.signup-btn-guest{

    width: auto;
    height: 52px;
    border-radius: 64px;
    color: #8129D9;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background-color: transparent;
    border: 1px solid #8129D9;

}

.img-cover-bottom{
    margin-top: -11.9rem;
}

.img-cover-bottom2{

    display: flex;
    justify-content: space-between;

}

.img-cover-bottom2 img{

    width:auto;
    height: 300px;
    object-fit: fill;
}

.img-cover-bottom img{
    
    object-fit: cover;    
    width: 100%;
}


.lefting-right-decor img {

    object-fit: cover;    
    width: 100%;

}


.background-model-login .form-signin-1 .inputs-log-sec .input-customize {


    display: flex;
    justify-content: center;

}

.background-model-login .form-signin-1 .inputs-log-sec .input-customize .form-control {

    width: 400px !important;
    background-color: white;

}

.background-model-login .form-signin-1 .inputs-log-sec .input-customize  .show-password-icon-2 {

    right: 3%;

}

.background-model-login .form-signin-1 .inputs-log-sec .LoG-btn  {

    width: 100%;

}

.background-model-login .form-signin-1 .inputs-log-sec .input-phone .react-tel-input .form-control {


    width: 400px !important;
    background-color: white !important;

}

.background-model-login .form-signin-1 .inputs-log-sec .input-phone .react-tel-input .selected-flag{

    background-color: white !important;
}




@media (max-width: 480px) {
  

    .background-model-login .form-signin-1 .inputs-log-sec .input-customize .form-control {

        width: 100% !important;
        background-color: white;
    
    }

    .background-model-login .form-signin-1 .inputs-log-sec .input-phone .react-tel-input .form-control {


        width: 100% !important;
        background-color: white !important;
    
    }
    
    .flexing-guest-page {
        display: flex;
        justify-content: center;
        padding-left: 0rem;
        padding-right: 0rem;
        padding-top: 0rem;
        flex-direction: column;
    }

    .left-guest-content {
    
        padding: 1rem;
        
    }

    .logo-sec img {

        width: 300px;

    }
    
    .btn-purple-guest button{

        width: 100%;
        height: 48px;
        padding: 13px 8px 13px 8px;
        gap: 8px;
        border-radius: 64px;
        background: #8129D9;
        color: white;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
        border: 0px;
    
    }

    .linkinput-class input {

        width: 100%;
    
    }

    .left-guest-content h6 { 

        font-family: Poppins;
        font-size: 26px;
        font-weight: 600;
        line-height: 45px;
        color: #000000;
        padding-top: 2rem;
        padding-left: 1rem;
    
    }


    .img-right-guest img{


        width: 100%;
        
    }

    .img-cover-bottom2 img{

        width:auto;
        height: 50px;
        object-fit: fill;
        display: none;

    }
    
    .img-cover-bottom img{
        object-fit: cover;    
        width: 100%;
        height: 100px;
        margin-bottom: auto;
        
    }

    .img-cover-bottom {
        margin-top: auto;
    }
    

    .lefting-right-decor img {
        object-fit: cover;    
        width: 100%;
        height: 10px;
        
    }

    .background-container {

        background-color: #EDEDED;
        height: 100vh !important;

    }
    
}



/* Target tablets (portrait and landscape) */
@media screen and (min-width: 768px) and (max-width: 1366px) {

    .flexing-guest-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0rem;
        padding-right: 0rem;
        padding-top: 0rem;
        flex-direction: column;
    }


    .background-container {

        background-color: #EDEDED;
        height: 105vh !important;

    }

    .img-cover-bottom {
 
        margin-top: auto;
    }
    

    .img-cover-bottom img{
        object-fit: cover;    
        width: 100%;
        height: auto;
    }


    .img-cover-bottom2 img{

        width:auto;
        height: 100px;
        object-fit: fill;
        display: none;

    }
    
    .lefting-right-decor img {
        object-fit: cover;    
        width: 100%;
        height: 50px;
        
    }
    
}