.progress-container {
    position: relative;
}

.progress-bar {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 98%;
    height: 5px;
    background-color: #DCDCDC !important;
    /* Passive color */
    transform: translate(-50%, -50%);
}

.progress-bar div {
    background: #533668;
    height: 5px;
    transition: 0.3s ease-in-out;
}

.progress-step {
    display: flex;
    justify-content: space-between;
    position: relative;
    counter-reset: myCounter;
    list-style: none;
    padding: 0;
    margin: 0;
}

.progress-step li {
    position: relative;
    z-index: 1;
    cursor: pointer;
    list-style: none;
    /* Remove any default numbering */

}

.progress-step li:before {
    display: block;
    content: counter(myCounter);
    counter-increment: myCounter;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
    background-color: #939393;
    transition: 0.3s ease-in-out;
    border: 4px solid transparent;
    width: 48px;
    height: 48px;
    padding: 5px;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
}

.progress-step li.complated:before {
    background-color: #533668;
    /* Active color */
    color: #fff;
}

.progress-step li.active:before {
    background-color: #533668;
    /* Darkened active color */
    color: #fff;
    animation: borderPulsate 1.5s infinite;
}

.progress-step li::after {
    content: '';
    display: block;
    /* To prevent duplicate text under the circle */
}

@keyframes borderPulsate {
    0% {
        border-color: #533668
            /* Darkened active color */
    }

    50% {
        border-color: rgba(204, 0, 0, 0);
        /* Transparent */
    }

    100% {
        border-color: #533668;
        /* Darkened active color */
    }
}