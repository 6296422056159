.flexing-new-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;

}

.New-Profile-1-contanier {

    width: 494px;
    height: auto;
    border-radius: 16px;
    background-color: #F5EEF9;
    box-shadow: 0px 4px 4px 0px #00000029;
}

.img-holder-container {

    position: relative;
    display: inline-block;
    width: 100%;
    height: auto;
}


.name-floating-theme1 {

    position: absolute;
    bottom: 35%;
    /* Adjust as needed */
    left: 2rem;
    /* Adjust as needed */
    color: white;
    /* Ensures text is visible on dark backgrounds */
    padding: 10px;
    border-radius: 5px;
}

.name-floating-theme1 h6 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 24px;
    line-height: 28.13px;
    letter-spacing: 2%;
    color: #FBFBFB;
}

.name-floating-theme1 p {
    font-family: Roboto;
    font-weight: 500;
    font-size: 20px;
    line-height: 23.44px;
    letter-spacing: 2%;
    color: #FBFBFB;


}


.social-links-new {

    display: flex;
    list-style-type: none;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 1rem;
    padding-top: 1rem;
}

.data-new-containers {

    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    flex-direction: column;
    row-gap: 2rem;
}

.data-container-new {


    width: 447px;
    height: 58px;
    border-radius: 16px;
    border-bottom-width: 3px;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-left: 16px;
    border-bottom: 3px solid #A36AB9;
    background-color: #FBF8FC;


}

.icon-subject2 {
    display: flex;
    align-items: center;
    width: 100%;
}

.copy-light-icon {
    margin-left: auto;
}


.icon-subject2 p {

    margin-bottom: 0px;
    margin-top: 0.5rem;
    margin-left: 1rem;
}


.img-holder-container {
    display: flex;
    align-items: center;
    /* Aligns images vertically */
    position: relative;
    width: 100%;
    /* Adjust width as needed */
    height: auto;
    /* Adjust height as needed */
}

.background-img {
    width: 100%;
    /* Make it fill the container */
    height: auto;
}

.profile-img {
    position: absolute;
    right: 10%;
    top: 70%;
    transform: translateY(-50%);
    width: 160px;
    height: 160px;
    border-radius: 50%;
}

.attachment-container-element-new {

    width: 447px;
    height: 58px;
    border-radius: 16px;
    border-bottom-width: 3px;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-left: 16px;
    border-bottom: 3px solid #A36AB9;
    background-color: #FBF8FC;
    display: flex;
    justify-content: space-between;

}

.ul-for-attachment-new {


    list-style-type: none;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 2rem;
    padding-top: 1.2rem;


}

.ul-for-attachment-new .paragraph-for-attachment-new {

    width: 80px;
    text-align: center;
    border: 0px;
    height: 38px;
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 55.1px;
    font-weight: 700;
    font-size: 16px;
    color: white;
    background: linear-gradient(180deg, #A36AB9 0%, #493053 100%);
    padding-top: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80px;
    /* Adjust to fit 3 characters */

}


.ul-for-attachment-new .btn-theme-one-purble {

    width: 142px;
    height: 32px;
    border-radius: 16px;
    padding-top: 4px;
    padding-right: 16px;
    padding-bottom: 4px;
    padding-left: 16px;
    background: linear-gradient(180deg, #A36AB9 0%, #493053 100%);
    border: 0px;
    color: white;
    font-family: Inter;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.36px;
    letter-spacing: 0%;

}

.ul-for-social-media {

    list-style-type: none;
    padding-left: 0rem;

}

.social-media-element {

    width: 100%;
    height: 64px;
    padding: 8px;
    border-radius: 100px;
    background: #FFFFFF;
    display: flex;
    margin-bottom: 1rem;

}

.small-icons-theme1 {
    padding-top: 1rem;
    margin-right: 2.5rem;
    display: flex;
    justify-content: flex-end;
    column-gap: 3rem;

}

.first-two-columns-icons {

    display: flex;
    column-gap: 0.5rem;

}


.list-of-non-link {

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
    width: 100%;
}

.Logoprofile-img {
    width: 206px;
    height: 84px;

}

.second-two-columns-icons {

    display: flex;
    column-gap: 0.5rem;

}


.get-your-card-btn {

    width: 196px;
    height: 58px;
    border-radius: 16px;
    background: linear-gradient(180deg, #A36AB9 0%, #493053 100%);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #FAFAFA;
    border: 0px;
    box-shadow: 2px 4px 4px 0px #0000001F;


}

.profile-img{
    
}

@media (max-width: 480px) {
    .Attachment-icon{

        width: 32px;
    }

    .flexing-new-profile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2rem;
    
    }
    .Logoprofile-img {

        width: 140px !important;
        height: 60px;


    }

    .New-Profile-1-contanier {

        width: 100%;

    }



    .data-container-new {


        width: 95%;



    }

    .get-your-card-btn {

        width: 100%;
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;

    }

    .attachment-container-element-new {

        width: 95%;

    }

    .profile-img {
        position: absolute;
        right: 5%;
        top: 80%;
        transform: translateY(-50%);
        width: 140px;
        height: 140px;
    }

    .name-floating-theme1 h6 {
 
        font-size: 19px;
       
    }

    .small-icons-theme1 {
        padding-top: 3rem;
        margin-right: 1rem;
        display: flex;
        justify-content: flex-end;
        column-gap: 0.5rem;
    
    }

    .first-two-columns-icons {

        display: flex;
        column-gap: 0.5rem;
    
    }

    

}