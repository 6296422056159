.Theme-2-layout {

    display: flex;
    column-gap: 5rem;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;
}

.img-sec-theme2 {}

.profile-circle-img-Theme2 {



    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}


.profile-circle-img-Theme2 h6 {
    padding-top: 1rem;
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    color: #000000;

}

.profile-circle-img-Theme2 p {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    color: #000000;

}

.right-sec-theme-three {

    width: auto;
    height: auto;
    border-left: 2px solid #C7C7C7;
    padding: 1rem;

}


.ul-for-social-media-theme-2 {

    list-style-type: none;
    display: flex;
    column-gap: 1rem;
}

.personal-information-theme2 {

    width: 848px;
    height: auto;
    padding: 32px;
    border-bottom: 1px solid #C7C7C7;

}

.personal-information-theme2 h6 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: #000000;

}



.data-layout-theme-2 {

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;


}



.card-layout-profile-theme-data-element-theme-2 {

    width: 199px;
    height: 88px;
    padding-top: 1rem;
    border-radius: 16px;
    display: flex;
    column-gap: 1rem;
}

.attachment-theme-2 {

    padding: 32px;


}

.attachment-theme-2 h6 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: #000000;




}


.attachment-ul-theme-2 {

    list-style-type: none;
    display: flex;
    padding: 0rem;
    padding-top: 1rem;
    column-gap: 1rem;

}

.attachment-container-element-theme-2 {

    width: 200px;
    height: 100px;
    border-radius: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border: 1px solid #C7C7C7;
}

.Attachment-icon-theme2 {


    width: 64px;
    height: 64px;

}

.paragraph-for-attachment-theme-2 {

    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: #000000;


}


@media (max-width: 480px) {

    .Theme-2-layout {

        display: flex;
        flex-direction: column;
        column-gap: 0rem;
        justify-content: center;
        align-items: center;
        padding-top: 5rem;
    }

    .personal-information-theme2 {

        width: 100%;
        height: auto;
        padding: 10px;
        border-bottom: 1px solid #C7C7C7;

    }


    .data-layout-theme-2 {

        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;


    }

    .personal-information-theme2 h6 {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        color: #000000;
    }


    .attachment-ul-theme-2 {

        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 0rem;
        padding-top: 1rem;
        column-gap: 1rem;

    }

    .right-sec-theme-three {

        width: 100%;
        height: auto;
        border-left: none;
        padding: 0rem;

    }


    .attachment-theme-2 {

        padding: 10px;


    }

    .attachment-ul-theme-2 {

        list-style-type: none;
        display: flex;
        flex-wrap: nowrap;
        padding: 0rem;
        padding-top: 1rem;
        column-gap: 1rem;
        overflow-x: scroll;
        gap: 1rem;
        scroll-snap-type: x mandatory;
        width: 100%;
        overflow-y: hidden;

    }

    .ul-for-social-media-theme-2 {

        list-style-type: none;
        display: flex;
        flex-wrap: nowrap;
        column-gap: 1rem;
        padding: 0px;
    }

    .attachment-container-element-theme-2 {

        min-width: 100px;
        height: 100px;
        border-radius: 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border: 1px solid #C7C7C7;
    }
}