.navbar-wrapper {
    position: fixed;
    top: 30px;
    left: 0;
    width: 100%;
    z-index: 900;
}

.navbar {
    background-color: #FFFFFF;
    border-radius: 40px;
    width: 90% !important;
    padding-right: 1.2rem !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    /* Adds a subtle shadow */

}

.tippytap-logo {
    height: 70px;
    padding-left: 1rem;

}

.nav-item {

    margin-top: 0.3rem;
}

.span-for-logo-title {
    color: #4925e1;
    font-size: 20px;
    font-weight: 600;
    font-family: Poppins;
    margin-left: 0.3rem;
    padding-top: 20px;
    text-decoration: none;

}

.login-nav-btn {
    color: #11021D !important;
    background-color: #EFF0EC !important;
    border: 0px;
    text-decoration: none;
    border-radius: 10px !important;
    width: 97px;
    height: 64px;
}

.singup-nav-btn {
    color: #FAFAFA !important;
    background-color: #6423DB !important;
    border: 0px;
    text-decoration: none;
    width: 163px;
    height: 64px;
    border-radius: 100px !important;
    margin-left: 0.7rem;
}

.profileicon-2 {

    width: 30px;
    height: 30px;

}

/* .    background-color: #EFF0EC; */

.profile-section {
    background-color: #EFF0EC;
    width: 56px;
    height: 64px;
    margin-top: -0.1rem;
    border-radius: 8px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

}

.cart-container-grey {

    width: 56px;
    height: 64px;
    border-radius: 8px;
    background-color: #EFF0EC;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.cart-container-grey img {


    width: 38px;
    height: 38px;
    padding: 0.2rem;

}

.counter-position-relative {

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}


.cart-count {
    position: absolute;
    /* Position it over the cart icon */
    top: -3rem;
    /* Adjust as needed to position above */
    right: -0.5rem;
    /* Adjust as needed to position above */
    background-color: #6423DB;
    /* Background color for the count */
    color: white;
    /* Text color */
    padding-left: 6px;
    padding-right: 5px;
    border-radius: 50%;
    /* Make it circular */
    font-size: 14px;
    /* Adjust font size as needed */
    font-weight: 700;
    width: auto;
    animation: none;
    display: flex;
    justify-content: center;

}

.bounce {
    animation: bounce 0.9s ease !important;
}


.collapsed-btns-menu {

    display: flex;

}

@media (max-width: 480px) {

    .dropdown-menu-end[data-bs-popper] {
        right: auto;
        left: 0px !important;
    }

    .navbar-wrapper {
        top: 25px;
    }

    .navbar-wrapper {
        position: fixed;
        top: 30px;
        left: 0;
        width: 100%;
        z-index: 900;
    }

    .navbar {
        background-color: #FFFFFF;
        border-radius: 40px;
        width: 90%;
        padding-right: 0px !important;

    }

    .tippytap-logo {
        height: 55px;
        padding-left: 1rem;

    }

    .collapsed-btns-menu {

        display: flex;
        justify-content: space-between;

    }

    .singup-nav-btn {
        color: #FAFAFA !important;
        background-color: #6423DB !important;
        border: 0px;
        text-decoration: none;
        width: 100%;
        height: 64px;
        border-radius: 100px !important;
        margin-left: 0px;
    }

    .login-nav-btn {
        color: #11021D !important;
        background-color: #EFF0EC !important;
        border: 0px;
        text-decoration: none;
        border-radius: 100px !important;
        width: 100%;
        height: 64px;
    }

    .profile-section {
        background-color: #EFF0EC;
        width: 56px;
        height: 64px;
        margin-top: -0.1rem;
        border-radius: 8px;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        margin-top: 1rem;
    }


}

@media only screen and (min-width: 768px) and (max-width: 1024px) {}