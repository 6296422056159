.wrap-elvate-section {

    background-color: #A6B4E6;

}

.flexing-section-elevate {

    display: flex;
    padding-top: 5rem;
    column-gap: 16%;
}

.elevate-titles-text {
    padding-top: 5rem;

}

.elevate-titles-text h6 {
    font-family: Poppins;
    font-size: 50px;
    font-weight: 600;
    line-height: 65px;
    text-align: left;
    color: #11021D;

}

.elevate-titles-text p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #11021D;

}

.elevateimg {}

@media (max-width: 480px) {


    .flexing-section-elevate {

        display: flex;
        flex-direction: column;
        padding-top: 5rem;
        column-gap: 16%;
    }

    .elevateimg {

        width: 100%;
        
    }


.elevate-titles-text h6 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: #11021D;

}

.elevate-titles-text p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: #11021D;

}

.elevate-titles-text {
    padding-top: 0rem;

}

.flexing-section-elevate {

    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;

    column-gap: 16%;
}


    
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {


    .flexing-section-elevate {

        display: flex;
        padding-top: 3rem;
        column-gap: 8%;
    }

    .elevateimg {

        width: 330px;
        height: auto;
    }

    .elevate-titles-text h6 {
        font-family: Poppins;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
        text-align: left;
        color: #11021D;
    
    }
    
    .elevate-titles-text p {
        font-family: Poppins;
        font-size: 19px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        color: #11021D;
    
    }

    .elevate-titles-text {
        padding-top: 1rem;
    
    }
    
    
}