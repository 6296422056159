.products-layout-home {
    padding-top: 1rem;

}

.specialofferheader {

    font-family: Poppins;
    font-size: 40px;
    font-weight: 500;
    line-height: 58.8px;
    letter-spacing: -1.1200000047683716px;
    text-align: center;
    color: #1E2330;
}



.products-options-home {

    display: flex;
    column-gap: 11%;
    padding: 1.5rem;
    padding-top: 5rem !important;
    background-image: url('/webapps/tippytap/src/Assets/Frame 2459 (5).png');
    background-repeat: no-repeat;
    background-size: fill;
    height: auto;
    padding-bottom: 5rem !important;
}

.height-adapt {

    height: 399px;

}

.WECARE-sec-wrap-color {

    background-color: #F3F3F1;
}

.WECARE-Sec {

    padding-top: 3rem;
    padding-bottom: 3rem;

}

.WECARE-titles h6 {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 500;
    line-height: 58.8px;
    letter-spacing: -1.1200000047683716px;
    text-align: center;
    color: #1E2330;

}

.WECARE-Sec .swiper {
    height: 450px !important;
    /* Ensures the Swiper itself takes full height */
}

.image-container-slidertwo {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* Ensure the image container takes full height */
    text-align: center;
}

.wecareimage {
    margin-top: 2rem;
    width: 830px;
    height: 420px;
    /* Ensure the image maintains aspect ratio */
    max-height: 300px;


}

.image-container-slidertwo p {

    padding-top: 1rem;
    font-family: Poppins;
    font-size: 38px;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: -1.1200000047683716px;
    text-align: center;
    color: #11021D;
}


.swiper-navigation-wrapper {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    position: absolute;
    top: 85%;
    /* Place it vertically centered */
    left: 50%;
    transform: translate(-50%, -50%);
    /* Center the wrapper */
    z-index: 10;
}

.swiper-button-prev-custom,
.swiper-button-next-custom {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    z-index: 10;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}

.swiper-button-prev-custom {
    left: 10px;
    background-image: url('/webapps/tippytap/src/Assets/Frame (2).png');
    /* Path to your previous button image */
}

.swiper-button-next-custom {
    right: 10px;
    background-image: url('/webapps/tippytap/src/Assets/Frame (3).png');
    /* Path to your next button image */
}


@media (max-width:768px){




}


/* @media (max-width: 480px) {

    .WECARE-titles h6 {
        font-family: Poppins;
        font-size: 32px;
        font-weight: 500;
        line-height: 58.8px;
        letter-spacing: -1.1200000047683716px;
        text-align: center;
        color: #1E2330;

    }

    .specialofferheader {

        font-family: Poppins;
        font-size: 30px;
        font-weight: 500;
        line-height: 58.8px;
        letter-spacing: -1.1200000047683716px;
        text-align: center;
        color: #1E2330;
    }

    .wecareimage {
        margin-top: 0rem;
        width: 90%;
        height: 300px;
        max-height: 100%;


    }

    .products-options-home {

        display: flex;
        flex-direction: column;
        column-gap: 11%;
        padding: 0rem;
        padding-top: 5rem !important;
        background-image: url('/webapps/tippytap/src/Assets/Frame 2459 (5).png');
        background-repeat: repeat;
        background-size: fill;
        height: 100%;
        padding-bottom: 0rem !important;
    }



    .image-container-slidertwo {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
    }

    .WECARE-Sec .swiper {
        height: 590px !important;
    }


    .image-container-slidertwo p {

        padding-top: 1rem;
        font-family: Poppins;
        font-size: 28px;
        font-weight: 500;
        line-height: 50px;
        letter-spacing: -1.1200000047683716px;
        text-align: center;
        color: #11021D;
    }

    .WECARE-Sec {
        padding-top: 0rem;
        padding-bottom: 0rem;
    }


    .products-options-home {

        display: flex;
        column-gap: 11%;
        padding: 1.5rem;
        padding-top: 2rem !important;
        background-image: url('/webapps/tippytap/src/Assets/Frame 2460 (4).png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-bottom: 2rem !important;
        justify-content: center;
        row-gap: 1rem;
        align-items: center;
    }


    .product-card-img-landing {

        width: 280px;
        height: 360px;

    }

    .product-card-img-landing {

        width: 280px;
        height: 360px;

    }

    .height-adapt {
        width: 280px;
        height: 100%;
    }




} */


/* @media only screen and (min-width: 768px) and (max-width: 1024px) {

    .products-options-home {

        display: flex;
        column-gap: 5%;
        padding: 1rem;
        padding-top: 5rem !important;
        background-image: url('/webapps/tippytap/src/Assets/Frame 2459 (5).png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: auto;
        padding-bottom: 5rem !important;
    }

    .specialofferheader {

        font-family: Poppins;
        font-size: 30px;
        font-weight: 500;
        line-height: 58.8px;
        letter-spacing: -1.1200000047683716px;
        text-align: center;
        color: #1E2330;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .product-card-img-landing {

        width: 195px;
        height: 280px;

    }

    .height-adapt {

        height: auto;

    }

    .products-layout-home {
        padding-top: 1rem;
        padding-bottom: 2rem;
    }

} */




/* @media (max-width: 1500px) {



.products-options-home {

    display: flex;
    column-gap: 5%;
    padding: 0.5rem;
    padding-top: 2rem !important;
    background-image: url('/webapps/tippytap/src/Assets/Frame 2460 (4).png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 2rem !important;
    justify-content: center;
    row-gap: 1rem;
    align-items: center;
    border-radius: 20px;
}

} */