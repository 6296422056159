.whatsapp-box {
    position: fixed;
    bottom: 0px;
    top: 50%;
    right: 0px;
    z-index: 1000;
    width: 62px;
    height: 154px;
    cursor: pointer;
  }
  
  .whatsapp-box img {
    width: 100%;
    height: 100%;
  }


  @media (max-width: 480px) {

    .whatsapp-box {
     
      height: 110px;
    }

  }