.Fill-Business-Card-Data {}

.order-data-layout {


    border: 1px solid #DCDCDC;
    border-radius: 24px 24px 24px 24px;
    margin-bottom: 1rem;
    background-color: white;
}



.Business-wrap-container {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 40px 64px 40px 64px;
    border-radius:24px 24px 24px 24px;
    padding-top: 1rem;
    /* padding-bottom: 0rem; makes conf in whole busines css */
    padding-bottom: 2rem !important; 
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: block;
    margin-bottom: 1rem;

}

.flexing-avaliable-card{

    display: flex;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    flex-wrap: wrap;
    column-gap: 1rem;

}


.different-cards-content-2{

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;

}

.different-cards-content .available-card-temp  {

    border-color: #DCDCDC;


}

.flexing-avaliable-card .available-card-temp {

    border-color: #DCDCDC;


}

.show-similar-cards {
    display: block; /* Or any styling for when the condition is true */
}
.hide-similar-cards {
    display: none; /* Or styling for when the condition is false */
}

@media (max-width: 480px) {

    .flexing-avaliable-card {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        padding-left: 0rem;
        padding-right: 0rem;
        padding-bottom: 2rem;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        row-gap: 1rem;
        column-gap: 1rem;
    }


}

