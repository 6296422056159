.flex-features {
  padding-top: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.Right-features-sec {
  padding-top: 2rem;
}

.Right-features-sec h6 {
  font-family: Poppins;
  font-size: 36px;
  font-weight: 600;
  line-height: 54px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.right-container {

  width: 300px;
}

.Right-features-sec p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: rgba(107, 107, 107, 1);
}

.card-true {
  display: flex;
  flex-direction: row;
  column-gap: 2%;
  margin-top: 0.3rem;
}

.card-true img {
  width: 26px;
  height: 26px;
}

.card-true h6 {

  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.text-key-features {
  width: 320px;
  margin-top: 3.5rem;
}



.text-key-features h6 {
  font-family: Poppins;
  font-size: 60px;
  font-weight: 700;
  line-height: 65px;
  letter-spacing: -1.7599999904632568px;
  text-align: left;
  color: #11021D;
}

.text-key-features p {

  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -1.1200000047683716px;
  text-align: left;
  color: #11021D;
}

.Get-started-btn-container button {

  width: 164px;
  height: 64px;
  /* UI Properties */
  background: #CA24D9;
  border-radius: 24px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1599999964237213px;
  text-align: center;
  border: 0px;
  color: #FFFFFF;

}


.Partner-Sec2 {

  display: flex;
  flex-direction: row;
  column-gap: 1%;
  padding-top: 3rem;
  padding-bottom: 5rem;
}

.iphone-container {
  position: relative;
  width: auto;
  /* Match the width of the iPhone image */
  height: 555px;
  /* Match the height of the iPhone image */
}

.iphone-image {
  width: auto;
  /* Adjust to container size */
  height: 113%;
  margin-left: 0rem;
}

.video-container {
  position: absolute;
  top: 4.7%;
  /* Adjust based on the position of the screen within the iPhone */
  left: 31%;
  /* Adjust based on the position of the screen within the iPhone */
  width: 100%;
  /* Adjust to fit the screen area of the iPhone */
  height: 100%;
  /* Adjust to fit the screen area of the iPhone */
  z-index: -1;
  /* Ensure the video is on top of the image */
}

video {
  width: 270px;
  height: 580px;
  object-fit: cover;
  border-radius: 20px;
  /* To match the screen corners */
  overflow: hidden;
  margin-left: -4.3rem;

}


.features-item-con {

  width: 250px;
  height: 250px;
  background: transparent linear-gradient(89deg, #4925E1 0%, #727274 100%) 0% 0% no-repeat padding-box;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 3rem;
  padding-top: 2.5rem;
}

.features-item-con img {

  width: 92px;
  height: 92px;

}

.features-item-con p {

  width: 152px;
  height: 93px;
  text-align: center;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
}

.swiper-container {
  max-width: 850px;
  /* Define a max-width for the Swiper container */
  margin: 0 auto;
  /* Center the container */
  width: 100%;


}

.swiper-wrapper {
  height: 350px;
  /* Set to desired height */
  padding-bottom: 4.4rem;
}



.swiper-button-next,
.swiper-button-prev {
  background: none;
  /* Remove default background */
  border: none;
  /* Remove border */
  color: transparent;
  /* Make the arrow icons invisible */

}

/* Optional: Remove arrow icons completely */
.swiper-button-next::after,
.swiper-button-prev::after {
  content: none;
}

.swiper-button-next {
  background-image: url('/webapps/tippytap/src/Assets/chevron-down@2x.png');
  /* Replace with your image path */
  background-size: 15px 15px;
  /* Width and height of the image */
  background-repeat: no-repeat;
  background-position: center;
  /* Centers the image within the button */
  background-color: #4B28DD;
  border-radius: 24px;
  margin-top: 7.5rem;
  width: 50px !important;
  height: 50px !important;

}

.swiper-button-prev {
  background-image: url('/webapps/tippytap/src/Components/chevron-down@2x.png');
  /* Replace with your image path */
  background-repeat: no-repeat;
  background-position: center;
  /* Centers the image within the button */
  background-color: #A8A8A8;
  background-size: 15px 15px;
  width: 50px !important;
  height: 50px !important;
  border-radius: 24px;
  margin-top: 7.5rem;

}

.swiper-first-container {}

@media (max-width: 480px) {


  .flex-features {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
  }

  .text-key-features h6 {
    font-family: Poppins;
    font-size: 42px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: -1.7599999904632568px;
    text-align: left;
    color: #11021D;
  }

  .text-key-features p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
    color: #11021D;
  }

  .Left-img-features img {

    width: 100%;
    height: 100%;

  }

  .Partner-Sec2 {

    display: flex;
    flex-direction: column-reverse;
    column-gap: -2%;
    padding-top: 0rem;
  }


  .features-item-con {

    width: 250px;
    height: 250px;
    background: transparent linear-gradient(89deg, #4925E1 0%, #727274 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 0rem;
  }

  .swiper-first-container {

    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: -6rem;
  }

  .swiper-container {

    margin-top: 2rem;
  }

  .swiper-wrapper {

    height: 500px;
    /* Set to desired height */

  }

  .iphone-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* Adjust container height to fit content */
    padding: 0;
    margin: 0;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 2rem;
  }

  .iphone-image {
    width: 100%;
    /* Set width as a percentage to make it responsive */
    /* Adjust to container size */
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }



  .video-container {
    position: absolute;
    top: 4%;
    /* Adjust based on the position of the screen within the iPhone */
    left: 0;
    z-index: -1;
    /* Ensure the video is on top of the image */
  }

  video {
    width: 81%;
    height: 92%;
    border-radius: 20px;
    /* To match the screen corners */
    overflow: hidden;
    margin-left: 2.3rem;

  }

  .swiper-button-next {

    margin-top: 5rem;


  }

  .swiper-button-prev {

    margin-top: 5rem;

  }

  .right-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Get-started-btn-container button {

    width: 100%;
    font-size: 20px;
    color: #FFFFFF;

  }


}


@media only screen and (min-width: 768px) and (max-width:1366px) {

  .Partner-Sec2 {

    display: flex;
    flex-direction: column;
    column-gap: 10%;
    justify-content: center;
    align-items: center;

  }

  .iphone-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100%;
    height: 300px;
    /* Adjust container height to fit content */
    padding: 0;
    margin: 0;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0rem;
    clear: both;
  }

  .text-key-features {

    margin-top: 3.5rem;
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;

  }

  .features-item-con {

    width: 200px;
    height: 230px;

  }

  .iphone-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* Adjust container height to fit content */
    padding: 0;
    margin: 0;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 2rem;
  }

  .iphone-image {
    width: 100%;
    /* Set width as a percentage to make it responsive */
    /* Adjust to container size */
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }



  .video-container {
    position: absolute;
    top: 4%;
    /* Adjust based on the position of the screen within the iPhone */
    left: 0;
    z-index: -1;
    /* Ensure the video is on top of the image */
  }

  video {
    width: 81%;
    height: 92%;
    border-radius: 20px;
    /* To match the screen corners */
    overflow: hidden;
    margin-left: 4.2rem;

  }

  .right-container {
    width: 100%;
    text-align: center !important;
  }

  .text-key-features {
    width: 100%;
    margin-top: 3.5rem;
  }



  .text-key-features h6 {
    font-family: Poppins;
    font-size: 60px;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: -1.7599999904632568px;
    text-align: left;
    color: #11021D;
  }

  .text-key-features p {

    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    line-height: 48px;
    text-align: left;
    color: #11021D;
  }

  .Get-started-btn-container button {

    width: 100%;
    font-size: 30px;
    color: #FFFFFF;

  }


}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait),
only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {

  video {
    width: 80%;
    height: 92%;
    border-radius: 20px;
    overflow: hidden;
    margin-left: 6rem;

  }

}