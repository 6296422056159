.Pricing-wrap {
  background-color: #F3F3F1;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.pricing-titles {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 0rem;
  padding-bottom: 2rem;
}

.pricing-titles2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 3rem;
}

.two-rows-features{

  display: grid;
  grid-template-columns: 1fr 1fr;

}

.multicard-header-card{
  font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
  color: #212121;


}

.feature-icon{
    width: 25px !important;
    height: 25px !important;
    margin-top: -0.1rem !important;
}

.pricing-titles2 h6 {
  font-family: "Rowdies", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 35.94px;
  text-align: center;
  color: #000000;
}

.pricing-titles2 p {
  font-family: "Rowdies", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.97px;
  text-align: center;
  color: #6b6b6b;
  width: 644px;
}

.pricing-titles h6 {
  font-family: Poppins;
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0.1599999964237213px;
  text-align: center;
  
}

.pricing-titles span {
  font-family: "Rowdies", sans-serif;
  font-size: 44px;
  font-weight: 700;
  line-height: 59.9px;
  text-align: center;
  color: #000000;
}

.pricing-titles p {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.1599999964237213px;
  text-align: center;  
}

.pricing-cards-grid {
  display: flex;
  column-gap: 3.5%;
}

.card20 {
  width: 410px;
  height: 670px;
  background: #ffffff;
  border: 1px solid #A19BD5;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  transition: border-color 4s ease;

}

.card20:hover{

  border: 5px solid #6423DB ;
  border-radius: 20px;
}

.header-card-flex {
  display: flex;
  flex-direction: row;
  column-gap: 26%;
}

.header-titles h6 {
  padding-top:0.5rem;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 38px;
  color: #000000;
  margin-bottom: 0px;
  text-align: left;
}

.decor-class {
  position: relative !important; /* Ensure the element is positioned */

}


.decor-class img {
  position: absolute; /* Ensures it's part of the stacking context */
  z-index: 1; /* Lower z-index to place it behind */
  top: -1rem; /* Adjust the position */
  left: 83.6%; /* Adjust the position */


}

.tippy-card-img-holder {
  position: relative !important; /* Ensure the element is positioned */
  z-index: 2 !important; /* Higher z-index to make it appear in front */
}


.header-titles span {
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  color: #000000;
}

.price-tag-float-right {
  position: relative;
  display: inline-block;
}

.pricetag-img {
  margin-top: -1.8rem;
  display: block;
}

.price-tag-text {
  position: absolute;
  top: -5%;
  left: 58%;
  transform: translateX(-50%);
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
  line-height: 45px;
  text-align: left;
  color: #6423DB;
}

.card-body20 {
  display: flex;
  flex-direction: column;
}



.counter-container,
.order-now-btn-container {
  display: flex;
  justify-content: center;
  margin-top: auto;

}

.card-features {
  display: flex;
  margin-top: 0.5rem;

}



.card-features-text {
  margin-left: 0.6rem;

  font-family: Poppins;
  font-size: 12.23px;
  font-weight: 500;
  line-height: 18.32px;
  text-align: left;
  color: #000000;
}

.order-now-btn1 {
  width: 338.5px;
  height: 64px;
  border-radius: 26px;
  border: 0px;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  line-height: 25.46px;
  color: #000000;
  background-color:  #EDB1FF;

}

.order-now-btn1:hover {
  background-color: #533668; 
  color: #ffffff; 
}

.order-now-btn-container .order-now-btn-BLUE {
  width: 338.5px;
  height: 64px;
  border-radius: 26px;
  border: 0px;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  line-height: 25.46px;
  color: white;
  background-color: #533668;
}

.order-now-btn-container .order-now-btn-BLUE:hover {
  background-color: #EDB1FF;
  color: black; 
  border: 0px solid #6423DB !important;
}


.order-now-btn-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
}


.card-features img {
  width: 17.47px;
  height: 15px;
  gap: 0px;
  margin-top: 0.1rem;
}

.Buy-now-btn {
  width: 328.5px;
  height: 52px;
  border-radius: 26px;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  line-height: 25.46px;
  color: white;
  background-color: #6423DB;
  border: 0px;
}

.buy-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.flex-pricing{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 2rem;
  padding-bottom: 1rem;

}

.flex-pricing p{

  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1599999964237213px;
  margin-top: 0.5rem;
}

.pricing-titles-grid-3 {
  padding-top: 3rem;

}

.pricing-titles-grid-3 h6{

  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0.1599999964237213px;
  text-align: center;
  

}

.flex-pricing-container-white{

  width: 395px;
  height: auto;
  padding: 1rem;
  border-radius: 32px;
  background: #FFFFFF;
  border:1px solid #E0E0E0	;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
  .pricing-cards-grid {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .card20 {
    justify-content: center;
    width: auto;
  }

  .pricing-titles {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 0rem;
    padding-bottom: 1rem;
  }

  .pricing-titles p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 300;
    line-height: 26.96px;
    color: #5a5a5a;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .pricing-titles2 h6 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    line-height: 35.94px;
    text-align: center;
    color: #000000;
  }

  .pricing-titles2 p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 20.97px;
    text-align: center;
    color: #6b6b6b;
    width: 100%;
  }

  .card-features-text {
    margin-left: 0.6rem;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.32px;
    text-align: left;
    color: #000000;
  }


.flex-pricing{
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding-top: 2rem;
  padding-bottom: 1rem;

}

.flex-pricing-container-white{

  width: 100%;
  height: auto;
  padding: 1rem;
  border-radius: 32px;
  background: #FFFFFF;
  border:1px solid #E0E0E0	;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing-titles h6 {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0.1599999964237213px;
  text-align: center;
  
}
}

@media only screen and (min-width: 768px)  and (max-width: 1366px){

  .pricing-cards-grid {
    display: flex !important;
    flex-direction: column;
    column-gap: 1%;
    row-gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  

  .card20 {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 1.75px 9.61px 0px #d1d0d6;
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .flex-pricing{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
  
  }
  
  .flex-pricing-container-white{
  
    width: 100%;
    height: auto;
    padding: 1rem;
    border-radius: 32px;
    background: #FFFFFF;
    border:1px solid #E0E0E0	;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pricing-titles h6 {
    font-family: Poppins;
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0.1599999964237213px;
    text-align: center;
    
  }

  .decor-class img {
    position: absolute; /* Ensures it's part of the stacking context */
    z-index: 1; /* Lower z-index to place it behind */
    top: -1rem; /* Adjust the position */
    left: 90.6%; /* Adjust the position */
  
  
  }
  
}


/* @media (max-width: 1500px) {
  .card20 {
    width: 100%;
    height: 670px;
    background: #ffffff;
    border: 1px solid #A19BD5;
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    transition: border-color 4s ease;
  
  }


.order-now-btn1 {
  width: 100%;
  height: 64px;
  border-radius: 26px;
  border: 0px;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  line-height: 25.46px;
  color: #000000;
  background-color:  #EDB1FF;

}

.decor-class img {

  width: 71px;

}
} */


/* @media screen and (max-width: 1024px) and (max-height: 1366px) {

  .pricing-cards-grid {
    display: flex;
    flex-direction: column;
    column-gap: 1%;
    row-gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  

  .card20 {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 1.75px 9.61px 0px #d1d0d6;
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
} */