.Nav-for-Profile-Scane {

    display: flex;
    align-items: center;
    /* Centers items vertically */
    justify-content: space-between;
    /* Positions the button to the right */
}

.background-color-profile-scanning {

    background-color: #F1F1F1;
    padding-top: 1rem;
    height: 100%;
    padding-bottom: 1rem;

}

.get-your-card-btn {

    width: 177px;
    height: 58px;
    border-radius: 100px;
    background: #7A47D0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #FAFAFA;
    border: 0px;
}


.profile-layout-section {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    column-gap: 5rem;
    row-gap: 3rem;
    justify-content: center;
    align-items: center;

}

.purple-sec {

    background: #4C4B95;
    width: 500px;
    height: auto;
    padding: 1rem;
    border-radius: 32px;
    box-shadow: 2px 2px 14.6px 1px #0000000D;


}

.profile-second-flex {

    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    justify-content: center;
    align-items: center;

}


.pink-sec {

    background: #A98DAE;
    width: 500px;
    height: auto;
    padding: 1rem;
    border-radius: 32px;
    box-shadow: 2px 2px 14.6px 1px #0000000D;


}

.light-pur-sec {

    background: #B0B2E5;
    width: 500px;
    height: auto;
    padding: 24px 64px 24px 64px;
    border-radius: 32px;


}

.profile-circle-img {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile-circle-img h6 {
    padding-top: 1rem;
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    color: #FFFFFF;

}

.profile-circle-img p {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    color: #FFFFFF;

}

.border-sec-lianer {}

.circle-img-profile {
    width: 206px;
    height: 206px;
    border: 3px solid transparent;
    /* Set a transparent border */
    /* Apply gradient as the border */
    object-fit: cover;
    border-radius: 50%;
    /* Ensures the image fills the circular frame without distortion */

}



.profile-circle-img h5 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #FFFFFF;

}

.profile-circle-img span {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #FFFFFF;
    text-align: center;

}

.ul-for-social-media {

    list-style-type: none;
    padding-left: 0rem;

}

.social-media-element {

    width: 100%;
    height: 64px;
    padding: 8px;
    border-radius: 100px;
    background: #FFFFFF;
    display: flex;
    margin-bottom: 1rem;

}

.extra-data-element {
    width: 100%;
    height: auto;
    padding: 8px 16px 8px 16px;
    border-radius: 100px;
    background: #FFFFFF;
    display: flex;
    margin-bottom: 1rem;
    justify-content: flex-start;
}

.extra-data-element h6 {
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #000000;
    padding-top: 0.7rem;
    margin-left: 0.5rem;
}

.extra-data-element p {
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #000000;
    padding-top: 0.7rem;
    margin-left: 0.5rem;
}

.social-media-element p {
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
    padding-top: 0.7rem;
    margin-left: 0.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;

}

.card-layout-profile-theme-data-element {

    background: #FFFFFF;
    width: 199px;
    height: 88px;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    justify-content: flex-start;
    column-gap: 0.5rem;
}


.card-layout-profile-theme-data-element-email {

    width: 100%;
    padding: 16px;
    background: #FFFFFF;
    border-radius: 16px;
    display: flex;
    justify-content: flex-start;
    column-gap: 0.5rem;
    overflow: hidden; /* Ensures content does not overflow */
    word-wrap: break-word; /* Ensures long text wraps within the container */
}

.bio-span {

    padding: 0rem 0rem 1rem 0rem;

}

.email-element-flexing {
    margin-bottom: 0px;
    max-width: 100%; /* Prevents exceeding the parent container width */
    overflow-wrap: break-word; /* Breaks long words to fit inside */
    white-space: normal; 
    text-align: center;
    margin-bottom: 0px;
    width:300px;
    
}

.fourxfour {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    row-gap: 2rem;

}

.ul-for-attachment {

    list-style-type: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-top: 1.2rem;
}

.attachment-container-element {

    width: auto;
    height: 80px;
    padding: 8px 16px 8px 16px;
    border-radius: 16px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;


}

.attachment-container-element button {
    width: 125px;
    height: 40px;
    border-radius: 50px;
    background: #4C4B95;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #FFFFFF;
    border: 0px;
    margin-top: 0.7rem;

}

.paragraph-for-attachment {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
    padding-top: 1.2rem;



}


.img-for-social-media-item {

    width: 48px;
    height: 48px;
    padding: 4px 4px 4.1px 4px;
    background-color: #000000;
    border-radius: 50px;
    color: white;



}

.flexing-personal-img-icons {

    display: flex;
    flex-direction: column;
    position: absolute;
    top: 2.5rem;
    /* Adjust to position correctly */
    right: -0.5rem;
    /* Adjust to position correctly */
    display: flex;
    gap: 5px;
    /* Space between icons */
    z-index: 10;
}


.flexing-personal-img-icons img {

    width: 40px;
    height: 40px;

}

.border-sec-lianer {
    position: relative;
    display: inline-block;
}


@media (max-width: 480px) {

    .card-layout-profile-theme-data-element-email {
        flex-direction: column; /* Stack elements vertically */
        row-gap: 0.5rem; /* Add some spacing between elements */
        align-items: flex-start; /* Align items to the start */
    }



    .profile-layout-section {
        padding-top: 3rem;
        display: flex;
        flex-direction: column;
        row-gap: 3rem;
        column-gap: 5rem;

    }

    .profile-circle-img h6 {
        padding-top: 1rem;
        font-family: Poppins;
        font-size: 28px;
        font-weight: 600;
        line-height: 42px;
        text-align: center;
        color: #FFFFFF;

    }

    .extra-data-element {
        width: 100%;
        height: 100%;
        padding: 8px 16px 8px 16px;
        border-radius: 100px;
        background: #FFFFFF;
        display: flex;
        margin-bottom: 1rem;
        justify-content: flex-start;
    }


    .background-color-profile-scanning {

        background-color: #F1F1F1;
        padding-top: 1rem;
        height: auto;

    }

    .purple-sec {

        background: #4C4B95;

        width: 100%;
        height: auto;
        padding: 1rem;
        border-radius: 32px;
        box-shadow: 2px 2px 14.6px 1px #0000000D;


    }


    .pink-sec {

        background: #A98DAE;
        width: 98%;
        height: auto;
        padding: 1rem;
        border-radius: 32px;
        box-shadow: 2px 2px 14.6px 1px #0000000D;


    }

    .light-pur-sec {

        background: #B0B2E5;
        width: 98%;
        height: auto;
        padding: 1rem;
        border-radius: 32px;
        margin-bottom: 1rem;

    }

    .ul-for-social-media {

        list-style-type: none;
        padding: 0px;
    }

    .social-media-element {

        width: 100%;
        height: 100%;
        padding: 8px 16px 8px 16px;
        border-radius: 100px;
        background: #FFFFFF;
        display: flex;
        margin-bottom: 1rem;

    }


        .fourxfour {

            display: flex;
            flex-wrap: wrap;
            column-gap: 2rem;
            row-gap: 2rem;
        
        }
        

   

    .card-layout-profile-theme-data-element {

        background: #FFFFFF;
        width: 100%;
        height: 63px;
        padding: 10px;
        border-radius: 16px;
        display: flex;
        justify-content: flex-start;
        column-gap: 1rem;
    }


    .card-layout-profile-theme-data-element p,
    h6 {

        font-size: 12px;

    }


    .get-your-card-btn {

        width: 130px;
        height: 58px;
        border-radius: 100px;
        background: #7A47D0;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        color: #FAFAFA;
        border: 0px;
    }

    /* .Logoprofile-img {
        width: 180px;
        height: 60px;

    } */

    .social-media-element p {
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        padding-top: 0.7rem;
        margin-left: 0.5rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
    }


    .paragraph-for-attachment {
        font-size: 12px;

    }

    .attachment-container-element {

        width: 100%;
        height: 80px;
        padding: 8px 16px 8px 16px;
        border-radius: 16px;
        background: #FFFFFF;
        display: flex;
        justify-content: space-between;
    
    
    }

}


@media only screen and (min-width: 768px) and (max-width: 1366px) {

 
    

    .profile-layout-section {
        padding-top: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 3rem;
        column-gap: 5rem;
        margin-bottom: 3rem;

    }

    .background-color-profile-scanning {

        background-color: #F1F1F1;
        padding-top: 1rem;
        height: 100%;
    
    }
    .purple-sec {

        background: #4C4B95;
    
        width: 632px;
        height: auto;
        padding: 24px 64px 24px 64px;
        border-radius: 32px;
        box-shadow: 2px 2px 14.6px 1px #0000000D;
    
    
    }
    

    
    .pink-sec {
    
        background: #A98DAE;
        width: 632px;
        height: auto;
        padding: 24px 64px 24px 64px;
        border-radius: 32px;
        box-shadow: 2px 2px 14.6px 1px #0000000D;
    
    
    }

    .light-pur-sec {

        background: #B0B2E5;
        width: 632px;
        height: auto;
        padding: 24px 64px 24px 64px;
        border-radius: 32px;
    
    }

    .social-media-element p {
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        padding-top: 0.7rem;
        margin-left: 0.5rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
    }
    
    .profile-second-flex {

        display: flex;
        flex-direction: column;
        row-gap: 3rem;
        padding-bottom: 2rem;    
    }


}