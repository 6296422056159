.add-pet-container {

    background: #D4CDDE;
    width: 100%;
    height: 100%;
    padding: 16px;
    border-radius: 16px;
    margin-bottom: 1rem;

}

.flexing-space-btw-container{

    display: flex;
    justify-content: space-between;
  }
  

.inputs-pink-layout {

    display: flex;
    column-gap: 1.5rem;
    margin-bottom: 1rem;

}

.inputs-pink-layout .form-control {

    width: 460px;

}

.Add-pet-btn {

    width: 200px;
    height: 58px;
    border-radius: 10px;
    background: #7A47D0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #FAFAFA;
    border: 0px;
    margin-bottom: 1rem;

}

.cancel-add-pet-btn {
    width: 200px;
    height: 38px;
    border-radius: 10px;
    background: var(--bs-body-bg);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #000000;
    border: 1px solid #EFF0EC;
}

.btn-section-to-add {

    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;

}

.Add-fields-pets {

    width: 200px;
    height: 38px;
    border-radius: 10px;
    background: #008000;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: white;
    border: 0px solid #EFF0EC;


}

.delete-pet {
    width: 200px;
    height: 38px;
    border-radius: 10px;
    background: #ff0f0f;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: white;
    border: 0px solid #EFF0EC;


}

.submit-btn-pets{
    width: 200px;
    height: 58px;
    border-radius: 10px;
    background: #7A47D0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    border: 1px solid #EFF0EC;
}


.floating-save-pets-btn{

    display: flex;
    justify-content: flex-end;

}

@media (max-width: 480px) {



.inputs-pink-layout {

    display: flex;
    flex-direction: column;
    column-gap: 1.5rem;
    margin-bottom: 1rem;

}


.flexing-space-btw-container{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Add-pet-btn {

    width: 100%;
    height: 58px;
    border-radius: 10px;
    background: #7A47D0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #FAFAFA;
    border: 0px;
    margin-bottom: 1rem;

}
  
.inputs-pink-layout .form-control {

    width: 100%;

}


.inputs-pink-layout {

    display: flex;
    column-gap: 1.5rem;
    margin-bottom: 1rem;
    row-gap: 1rem;
}


.cancel-add-pet-btn {
    width: 100%;
    height: 38px;
    border-radius: 10px;
    background: var(--bs-body-bg);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #000000;
    border: 1px solid #EFF0EC;
}

.floating-save-pets-btn{

    display: flex;
    justify-content: center;

}

}


@media (min-width: 768px) and (max-width:1366px) {


    .inputs-pink-layout .form-control {

        width: 100%;
    
    }
    


}